import { CloseCircleFilled } from "@ant-design/icons";
import { Button } from "antd";
import styled from "styled-components";

export const BJClearButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <StyledButton size="small" type="primary" onClick={onClick}>
      <StyledText>Clear all</StyledText>
      <CloseCircleFilled />
    </StyledButton>
  );
};

const StyledButton = styled(Button)`
  margin-left: 0.5rem;
`;

const StyledText = styled.text`
  margin-right: 0.2rem;
`;
