import { Form, Typography } from "antd";
import * as yup from "yup";

import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FormModal } from "../../components";
import { DropAndCrop } from "../../components/DropAndCrop";
import { useCountry } from "../../context";
import { useToolsBanner } from "../../hooks/useToolsBanner";
import { AspectRatio, formValidationError } from "../../utils";
import { BJDeeplinkFormInput } from "../../components/theme/molecules/formItems/BJDeeplinkFormInput";
import { BJSelectFormItemLevel } from "../../components/theme/molecules/formItems/BJFormSelectItemLevel";

interface Props {
  bannerId: ToolsBanner["id"] | null;
  banners: ToolsBanner[];
  show: boolean;
  onHide: () => void;
  onAdd: () => void;
}

type FormValues = {
  deepLink: string;
  level: number;
  translations: {
    [locale: string]: {
      imageUrl: string;
      blurhash?: string;
    };
  };
};

export const AddNewToolsBannerModal = ({
  bannerId,
  banners,
  show,
  onHide,
  onAdd,
}: Props) => {
  const { currentCountry } = useCountry();
  const [banner, setBanner] = useState<ToolsBanner | null>(null);

  const { uploadBannerImage, createBanner, updateBanner } = useToolsBanner();

  const schema = yup.object().shape({
    deepLink: yup.string().required("Please enter a deep link"),
    translations: yup.object().shape(
      currentCountry?.locales.reduce((acc, item) => {
        acc[item.key] = yup.object().shape({
          imageUrl: yup
            .string()
            .required(`Please upload a banner image for ${item.label}`)
            .nullable(),
        });
        return acc;
      }, {} as any)
    ),
  });

  const {
    formState: { errors, dirtyFields },
    reset,
    setValue,
    control,
    getValues,
    handleSubmit,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      translations: {},
    },
  });

  useEffect(() => {
    if (!bannerId) return;
    const banner = banners.find(b => b.id === bannerId);
    if (!banner) return;
    setBanner(banner);
    reset({
      ...banner,
      level: banner.level ?? 0,
    });
  }, [bannerId, banners, reset]);

  const onSubmit = (values: FormValues) => {
    if (banner) {
      updateBanner(banner.id, {
        ...banner,
        ...values,
      });
    } else {
      createBanner({ ...values, sortOrder: banners.length }).then(() => {
        reset({
          translations: {},
          deepLink: "",
          level: 0,
        });
      });
    }
    onAdd();
  };

  const copyToNotificationDeepLink = (link: string) => {
    setValue("deepLink", link, { shouldDirty: true });
  };

  const onHandleHide = () => {
    setBanner(null);
    reset({ translations: {}, deepLink: "", level: 0 });
    onHide();
  };

  const handleUploadedImageBlurhash = (url: string | null, locale: string) => {
    const temp = { ...getValues("translations") };
    temp[locale] = {
      ...temp[locale],
      blurhash: url,
    };
    setValue("translations", temp, { shouldDirty: true });
  };

  const isDirty = !!Object.keys(dirtyFields).length;

  return (
    <FormModal
      enableDelete={false}
      enableSave={isDirty}
      error={errors as any}
      modalTitle={`Add New Tools Banner`}
      onHide={onHandleHide}
      onSubmit={handleSubmit(onSubmit, formValidationError)}
      show={show}
    >
      {bannerId && (
        <Typography.Text
          type="secondary"
          style={{ display: "block", marginBottom: 12 }}
        >
          Banner ID: {bannerId}
        </Typography.Text>
      )}

      {currentCountry.locales.map(locale => (
        <Form.Item
          key={locale.key}
          required
          label={`Banner Image (${locale.label})`}
          extra=""
          validateStatus={
            errors.translations?.[locale.key]?.imageUrl ? "error" : undefined
          }
          help={
            errors.translations?.[locale.key]?.imageUrl?.message && (
              <Typography.Paragraph type="danger">
                {errors.translations?.[locale.key]?.imageUrl?.message}
              </Typography.Paragraph>
            )
          }
        >
          <Controller
            control={control}
            name={`translations.${locale.key}.imageUrl`}
            render={({ field: { onChange } }) => (
              <DropAndCrop
                extra="Please upload a banner image with size of 800 x 200"
                allowNaturalImageUpload={true}
                title={`Banner Image (${locale.label})`}
                initialUrl={banner?.translations?.[locale.key]?.imageUrl}
                setUploadUrl={url => onChange(url)}
                setBlurhash={url =>
                  handleUploadedImageBlurhash(url, locale?.key)
                }
                uploadImage={uploadBannerImage}
                lockedRatio={AspectRatio.Free}
                defaultCropBoxWidth={100}
                defaultCropBoxHeight={100}
                croppable={true}
              />
            )}
          />
        </Form.Item>
      ))}

      <BJDeeplinkFormInput
        control={control}
        error={!!errors.deepLink}
        label={"Deep link"}
        message={errors.deepLink?.message}
        required
        fieldName={"deepLink"}
        title="Banner"
        copyToNotificationDeepLink={copyToNotificationDeepLink}
      />

      <BJSelectFormItemLevel
        control={control}
        error={!!errors?.level}
        message={errors?.level?.message}
        fieldName="level"
      />
    </FormModal>
  );
};
