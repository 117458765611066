import firebase from "../firebase";
import { CollectionPaths } from "../helper/collections";
import { logException } from "../utils/exceptionLogger";
import { CountryCodeService } from "./CountryCodeService";

export interface Report {
  icon?: string | null;
  parentKey?: string;
  id: string;
  title: string;
  url: string;
}

export interface ReportMenu {
  icon?: string | null;
  id: string;
  title: string;
}

export class ReportsService extends CountryCodeService {
  constructor(countryCode: string) {
    super(countryCode);
  }

  static toReport = (doc: firebase.firestore.DocumentSnapshot): Report => {
    const data = doc.data();
    return {
      id: doc.id,
      title: data?.title,
      url: data?.url,
      parentKey: data?.parentKey || null,
      icon: data?.icon || null,
    };
  };

  static toMenu = (doc: firebase.firestore.DocumentSnapshot): ReportMenu => {
    const data = doc.data();
    return {
      id: doc.id,
      title: data?.title,
      icon: data?.icon || null,
    };
  };

  subscribe = (
    callback: (
      err: Error | null,
      reportsData: Report[],
      menus: ReportMenu[]
    ) => void
  ) => {
    const reportsRef = firebase
      .firestore()
      .collection(CollectionPaths.Reports["se"]);
    const menusRef = firebase
      .firestore()
      .collection(CollectionPaths.ReportMenus["se"]);

    const unsubscribeReports = reportsRef.onSnapshot(async reportsSnapshot => {
      try {
        const reports = reportsSnapshot.docs.map(ReportsService.toReport);

        const menusSnapshot = await menusRef.get();
        const menus = menusSnapshot.docs.map(ReportsService.toMenu);

        callback(null, reports, menus);
      } catch (err) {
        logException(err);
        callback(err as Error, [], []);
      }
    });

    return () => {
      unsubscribeReports();
    };
  };

  getAllReports = async (): Promise<Report[]> => {
    try {
      const snapshot = await firebase
        .firestore()
        .collection(CollectionPaths.Reports["se"])
        .get();
      return snapshot.docs.map(ReportsService.toReport);
    } catch (err) {
      logException(err);
      throw new Error("Failed to fetch reports");
    }
  };

  getAllMenus = async (): Promise<ReportMenu[]> => {
    try {
      const snapshot = await firebase
        .firestore()
        .collection(CollectionPaths.ReportMenus["se"])
        .get();
      return snapshot.docs.map(ReportsService.toMenu);
    } catch (err) {
      logException(err);
      throw new Error("Failed to fetch report menus");
    }
  };

  createReport = (data: Partial<Report>) =>
    firebase.firestore().collection(CollectionPaths.Reports["se"]).add(data);

  createMenu = (data: Partial<ReportMenu>) =>
    firebase
      .firestore()
      .collection(CollectionPaths.ReportMenus["se"])
      .add(data);

  updateReport = (id: string, data: Partial<Report>) =>
    firebase
      .firestore()
      .collection(CollectionPaths.Reports["se"])
      .doc(id)
      .update(data);

  updateMenu = (id: string, data: Partial<ReportMenu>) =>
    firebase
      .firestore()
      .collection(CollectionPaths.ReportMenus["se"])
      .doc(id)
      .update(data);

  deleteReport = (id: string) =>
    firebase
      .firestore()
      .collection(CollectionPaths.Reports["se"])
      .doc(id)
      .delete();

  deleteMenu = (id: string) =>
    firebase
      .firestore()
      .collection(CollectionPaths.ReportMenus["se"])
      .doc(id)
      .delete();
}
