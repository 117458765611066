import { SizeType } from "antd/lib/config-provider/SizeContext";
import { useState } from "react";
import { Control, Path } from "react-hook-form";
import { FaExternalLinkAlt } from "react-icons/fa";
import { BJMainDeepLinkModal } from "../../../BJMainDeepLinkModal";
import { IconWrapper } from "../../../styled/BJCommonStyles";
import { BJInputFormItem } from "./BJFormInput";

export const BJDeeplinkFormInput = <T,>({
  error,
  message,
  control,
  fieldName,
  copyToNotificationDeepLink,
  copyContentId,
  title,
  required,
  label,
  disabled = false,
  disableDeepLinkModal = false,
}: {
  error?: boolean;
  message?: string;
  label: string;
  required?: boolean;
  control: Control<T>;
  fieldName: Path<T>;
  autoFocus?: boolean;
  disabled?: boolean;
  extra?: string;
  placeHoder?: string;
  type?: string;
  size?: SizeType;
  showInfo?: boolean;
  autoComplete?: string;
  allowCopy?: boolean;
  rows?: number;
  copyToNotificationDeepLink: (deepLink: string) => void;
  copyContentId?: (contentId: string) => void;
  title: string;
  disableDeepLinkModal?: boolean;
}) => {
  const [isDLVisible, setisDLVisible] = useState(false);

  const DeepLinkModalActions = {
    show: () => {
      setisDLVisible(true);
    },
    hide: () => {
      setisDLVisible(false);
    },
  };

  return (
    <>
      <BJInputFormItem
        control={control}
        error={error}
        label={label ?? "Deep link"}
        message={message}
        required={!!required}
        fieldName={fieldName}
        disabled={disabled}
        suffix={
          !disableDeepLinkModal && (
            <IconWrapper
              onClick={() => !disabled && DeepLinkModalActions.show()}
            >
              <FaExternalLinkAlt />
            </IconWrapper>
          )
        }
      />
      <BJMainDeepLinkModal
        title={title}
        isVisible={isDLVisible}
        modalActions={DeepLinkModalActions}
        copyToHOC={deepLink => {
          copyToNotificationDeepLink(deepLink);
          DeepLinkModalActions.hide();
        }}
        copyContentIdToHOC={contentId => {
          copyContentId && copyContentId(contentId);
        }}
      />
    </>
  );
};
