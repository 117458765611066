import { useEffect, useState } from "react";
import { ReportsService, Report, ReportMenu } from "../services/ReportsService";
import { useCountry } from "../context";

export const useReports = () => {
  const [reports, setReports] = useState<Report[]>([]);
  const [menus, setMenus] = useState<ReportMenu[]>([]);
  const [loading, setLoading] = useState(true);
  const countryCode = useCountry()?.currentCountry?.abb;

  useEffect(() => {
    const reportsService = new ReportsService(countryCode);

    const fetchReportsAndMenus = async () => {
      setLoading(true);
      try {
        const [fetchedReports, fetchedMenus] = await Promise.all([
          reportsService.getAllReports(),
          reportsService.getAllMenus(),
        ]);
        setReports(fetchedReports);
        setMenus(fetchedMenus);
      } catch {
        setReports([]);
        setMenus([]);
      } finally {
        setLoading(false);
      }
    };

    const unsubscribe = reportsService.subscribe(
      (err: any, updatedReports: Report[], updatedMenus: ReportMenu[]) => {
        if (err) {
          setReports([]);
          setMenus([]);
          setLoading(false);
          return;
        }
        setReports(updatedReports);
        setMenus(updatedMenus);
        setLoading(false);
      }
    );

    fetchReportsAndMenus();

    return () => {
      unsubscribe();
    };
  }, [countryCode]);

  return { reports, menus, loading };
};
