import React, { useEffect, useState } from "react";
import { Tabs, Row, Col, List, Typography } from "antd";
import {
  useMonthInformation,
  useOffers,
  useVerifiers,
  useCountry,
} from "../../context";
import { FormEdit, FormEditType } from "../../components";
import { useNavigate, useParams } from "react-router-dom";
import { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { BJMdFormItem } from "../../components/theme/molecules/formItems/BJFormMarkdown";
import { ContentSelector } from "../Popular/ContentSelector";
import {
  ContentListType,
  capitalizeFirstLetter,
  formValidationError,
  isEqualArrays,
  recommendedContentTitle,
  convertToYears,
  HALF_YEARLY_MONTHS,
} from "../../utils";
import { BJSelectFormItem } from "../../components/theme";
import { formatPayload } from "../../helper/recommendedContent";
import { UpdateTryItListItemModal } from "./Modal/UpdateTryItListItemModal";
import BJButton, { ButtonTypes } from "../../components/theme/atoms/Button";
import { commonErrors } from "../../language";

type FormValues = {
  development: {
    offerId1?: string;
    offer1?: Offer | null;
    offerId2?: string;
    offer2?: Offer | null;
    verifierId?: string;
    verifier?: Verifier | null;
    recommendedContent?: RecommendedContent[];
    translations?: {
      [locale: string]: {
        body?: string;
        intro?: string;
      };
    };
  };
  parent: {
    offerId1?: string;
    offer1?: Offer | null;
    offerId2?: string;
    offer2?: Offer | null;
    verifierId?: string;
    verifier?: Verifier | null;
    recommendedContent?: RecommendedContent[];
    translations?: {
      [locale: string]: {
        body?: string;
        intro?: string;
      };
    };
  };
  tryIt: {
    offerId1?: string;
    offer1?: Offer | null;
    offerId2?: string;
    offer2?: Offer | null;
    verifierId?: string;
    verifier?: Verifier | null;
    recommendedContent?: RecommendedContent[];
    items: TryItListItem[];
    translations?: {
      [locale: string]: {
        body?: string;
        intro?: string;
      };
    };
  };
  tryItList: TryItListItem[];
  month: number;
  week: number;
};

const { requiredError } = commonErrors;

export const MonthInformationPage = () => {
  const { offers } = useOffers();
  const { verifiers } = useVerifiers();
  const { id, type } = useParams<string>();
  const {
    monthInformation,
    loading,
    updateMonthlyInformation,
    updateMonthlyInfoMetadata,
    deleteMonthlyInfoMetadata,
    createMonthlyInformation,
    deleteMonthlyInformation,
    monthlyInformation,
    weeklyInformation,
  } = useMonthInformation(id);
  const { primaryLocale } = useCountry();
  const navigate = useNavigate();

  const dateType =
    type === "month" || monthInformation?.month ? "month" : "week";

  const dataNumbers = Array.from(
    { length: dateType === "month" ? 24 : 13 },
    (_, index) => index + 1
  )
    .concat(dateType === "month" ? HALF_YEARLY_MONTHS : [])
    .filter(data =>
      type === "month" || monthInformation?.month
        ? !monthlyInformation.some(item => item.month === data)
        : !weeklyInformation.some(item => item.week === data)
    );

  const [tryItListItems, setTryItListItems] = useState<TryItListItem[]>([]);
  const [developmentRecommendedContent, setDevelopmentRecommendedContent] =
    useState<RecommendedContent[]>([]);
  const [parentRecommendedContent, setParentRecommendedContent] = useState<
    RecommendedContent[]
  >([]);
  const [tryItListRecommendedContent, setTryItListRecommendedContent] =
    useState<RecommendedContent[]>([]);
  const [showAddTryItListItem, setShowAddTryItListItem] = useState(false);
  const [selectedTryItListItem, setSelectedTryItListItem] =
    useState<TryItListItem | null>(null);

  const schema = yup.object().shape({
    month:
      dateType === "month" && yup.number().required(`Month: ${requiredError}`),
    week:
      dateType === "week" && yup.number().required(`Week: ${requiredError}`),
  });

  const {
    formState,
    formState: { errors },
    handleSubmit,
    reset,
    control,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (monthInformation === null) {
      return;
    }
    setTryItListItems(monthInformation.tryIt.items || []);
    setParentRecommendedContent(
      monthInformation.parent?.recommendedContent || []
    );
    setTryItListRecommendedContent(
      monthInformation.tryIt?.recommendedContent || []
    );
    reset(monthInformation);
  }, [monthInformation, reset]);

  const onSubmit: SubmitHandler<FormValues> = async data => {
    const developmentTranslations: MonthInformation["development"]["translations"] =
      {};
    const parentTranslations: MonthInformation["parent"]["translations"] = {};
    const tryItTranslations: MonthInformation["tryIt"]["translations"] = {};
    for (const [key, value] of Object.entries(
      data.development?.translations ?? {}
    )) {
      developmentTranslations[key] = {
        intro: value?.intro?.trim(),
        body: value?.body?.trim(),
      };
    }
    for (const [key, value] of Object.entries(
      data.parent?.translations ?? {}
    )) {
      parentTranslations[key] = {
        intro: value?.intro?.trim(),
        body: value?.body?.trim(),
      };
    }
    for (const [key, value] of Object.entries(data.tryIt?.translations ?? {})) {
      tryItTranslations[key] = {
        intro: value?.intro?.trim(),
        body: value?.body?.trim(),
      };
    }
    const mappedMonthlyInfo = {
      ...monthInformation,
      development: {
        offerId1: data.development?.offerId1,
        offerId2: data.development?.offerId2,
        verifierId: data.development?.verifierId,
        verifier: verifiers?.find(x => x.id === data.development?.verifierId),
        recommendedContent: developmentRecommendedContent.map(formatPayload),
        recommendedContentMap: developmentRecommendedContent
          .map(formatPayload)
          .reduce((acc, item) => {
            acc[item.id] = item;
            return acc;
          }, {} as { [key: string]: CommonContentV2 }),
        translations: developmentTranslations,
      },
      parent: {
        offerId1: data.parent?.offerId1,
        offerId2: data.parent?.offerId2,
        verifierId: data.parent?.verifierId,
        verifier: verifiers?.find(x => x.id === data.parent?.verifierId),
        recommendedContent: parentRecommendedContent.map(formatPayload),
        recommendedContentMap: parentRecommendedContent
          .map(formatPayload)
          .reduce((acc, item) => {
            acc[item.id] = item;
            return acc;
          }, {} as { [key: string]: CommonContentV2 }),
        translations: parentTranslations,
      },
      tryIt: {
        offerId1: data.tryIt?.offerId1,
        offerId2: data.tryIt?.offerId2,
        verifierId: data.tryIt?.verifierId,
        verifier: verifiers?.find(x => x.id === data.tryIt?.verifierId),
        recommendedContent: tryItListRecommendedContent.map(formatPayload),
        recommendedContentMap: tryItListRecommendedContent
          .map(formatPayload)
          .reduce((acc, item) => {
            acc[item.id] = item;
            return acc;
          }, {} as { [key: string]: CommonContentV2 }),
        items: tryItListItems,
        translations: tryItTranslations,
      },
      tryItList: tryItListItems,
    };
    mappedMonthlyInfo[dateType] = dateType === "month" ? data.month : data.week;
    const metaData = {
      fieldType: dateType === "month" ? "months" : "weeks",
      monthOrWeek: dateType === "month" ? data.month : data.week,
    };
    //this will update the metadata for either month or week
    await updateMonthlyInfoMetadata(metaData);

    if (monthInformation?.id) {
      await updateMonthlyInformation({
        ...mappedMonthlyInfo,
        id: monthInformation.id,
      });
    } else {
      const { id } = await createMonthlyInformation({
        ...mappedMonthlyInfo,
      });
      return navigate(`../${id}`);
    }
  };

  const handleOnDelete = async () => {
    if (monthInformation.id) {
      deleteMonthlyInformation(monthInformation.id);
      deleteMonthlyInfoMetadata({
        fieldType: dateType === "month" ? "months" : "weeks",
        monthOrWeek:
          dateType === "month" ? monthInformation.month : monthInformation.week,
      });
      navigate("../");
    }
  };

  const isDirty =
    !!Object.keys(formState.dirtyFields).length ||
    !isEqualArrays(
      monthInformation?.development?.recommendedContent ?? [],
      developmentRecommendedContent
    ) ||
    !isEqualArrays(
      monthInformation?.parent?.recommendedContent ?? [],
      parentRecommendedContent
    ) ||
    !isEqualArrays(
      monthInformation?.tryIt?.recommendedContent ?? [],
      tryItListRecommendedContent
    ) ||
    !isEqualArrays(monthInformation?.tryIt.items ?? [], tryItListItems);

  const onAddTryItListItem = (item: TryItListItem) => {
    const sortOrderNumericArray =
      tryItListItems.length === 0
        ? [-1]
        : tryItListItems?.map(({ sortOrder }) => sortOrder);
    const highestSortOrder = Math.max(...sortOrderNumericArray);
    item.sortOrder = highestSortOrder + 1;
    if (tryItListItems) setTryItListItems(old => [...old, item]);
    else setTryItListItems([item]);
    setShowAddTryItListItem(false);
  };

  const onUpdateTryItListItem = (item: TryItListItem) => {
    setTryItListItems(old => old.map(t => (t.id === item.id ? item : t)));
    setSelectedTryItListItem(null);
  };

  const onDeleteTryItListItem = (id: TryItListItem["id"]) => {
    setTryItListItems(old => old.filter(t => t.id !== id));
    setSelectedTryItListItem(null);
  };

  const renderDevelopment = (locale: Locale) => {
    return (
      <>
        <Row gutter={6}>
          <Col span={6}>
            <BJSelectFormItem
              control={control}
              error={!!errors?.[dateType]}
              label={capitalizeFirstLetter(dateType)}
              message={errors?.[dateType]?.message}
              optionsList={dataNumbers.map(x => ({
                key: x,
                value: x,
                display: `${x} ${convertToYears(x)}`,
              }))}
              fieldName={dateType}
              required
            />
          </Col>
          <Col span={6}>
            <BJSelectFormItem
              includeEmpty
              control={control}
              error={!!errors.development?.verifierId}
              label={"Verifier"}
              message={errors.development?.verifierId?.message}
              optionsList={verifiers?.map(x => ({
                key: x.id,
                value: x.id,
                display: x.name,
              }))}
              fieldName={"development.verifierId"}
            />
          </Col>
          <Col span={6}>
            <BJSelectFormItem
              includeEmpty
              control={control}
              error={!!errors.development?.offerId1}
              label={"Offer 1"}
              message={errors.development?.offerId1?.message}
              optionsList={offers?.map(x => ({
                key: x?.id,
                value: x?.id,
                display: x?.translations?.[locale.key]?.title ?? x?.title,
              }))}
              fieldName={"development.offerId1"}
            />
          </Col>
          <Col span={6}>
            <BJSelectFormItem
              includeEmpty
              control={control}
              error={!!errors.development?.offerId2}
              label={"Offer 2"}
              message={errors.development?.offerId2?.message}
              optionsList={offers.map(x => ({
                key: x.id,
                value: x.id,
                display: x?.translations?.[locale.key]?.title ?? x?.title,
              }))}
              fieldName={"development.offerId2"}
            />
          </Col>
        </Row>
        <BJMdFormItem
          control={control}
          label={`Intro (${locale?.label ?? ""})`}
          fieldName={`development.translations.${locale.key}.intro`}
          key={`development.translations.${locale.key}.intro`}
        />
        <BJMdFormItem
          control={control}
          label={`Body (${locale?.label ?? ""})`}
          fieldName={`development.translations.${locale.key}.body`}
          key={`development.translations.${locale.key}.body`}
        />
        <ContentSelector
          headerTitle={recommendedContentTitle}
          displayButtons={false}
          setManagedContent={setDevelopmentRecommendedContent}
          initialContent={
            monthInformation?.development?.recommendedContent ?? []
          }
          contentListType={ContentListType.RecommendedContent}
        />
      </>
    );
  };

  const renderParent = (locale: Locale) => {
    return (
      <>
        <Row gutter={6}>
          <Col span={6}>
            <BJSelectFormItem
              control={control}
              error={!!errors?.[dateType]}
              label={capitalizeFirstLetter(dateType)}
              message={errors?.[dateType]?.message}
              optionsList={dataNumbers.map(x => ({
                key: x,
                value: x,
                display: `${x} ${convertToYears(x)}`,
              }))}
              fieldName={dateType}
              required
            />
          </Col>
          <Col span={6}>
            <BJSelectFormItem
              includeEmpty
              control={control}
              error={!!errors.parent?.verifierId}
              label={"Verifier"}
              message={errors.parent?.verifierId?.message}
              optionsList={verifiers?.map(x => ({
                key: x.id,
                value: x.id,
                display: x.name,
              }))}
              fieldName={"parent.verifierId"}
            />
          </Col>
          <Col span={6}>
            <BJSelectFormItem
              includeEmpty
              control={control}
              error={!!errors.parent?.offerId1}
              label={"Offer 1"}
              message={errors.parent?.offerId1?.message}
              optionsList={offers?.map(x => ({
                key: x?.id,
                value: x?.id,
                display: x?.translations?.[locale.key]?.title ?? x?.title,
              }))}
              fieldName={"parent.offerId1"}
            />
          </Col>
          <Col span={6}>
            <BJSelectFormItem
              includeEmpty
              control={control}
              error={!!errors.parent?.offerId2}
              label={"Offer 2"}
              message={errors.parent?.offerId2?.message}
              optionsList={offers.map(x => ({
                key: x.id,
                value: x.id,
                display: x?.translations?.[locale.key]?.title ?? x?.title,
              }))}
              fieldName={"parent.offerId2"}
            />
          </Col>
        </Row>
        <BJMdFormItem
          control={control}
          label={`Intro (${locale?.label ?? ""})`}
          fieldName={`parent.translations.${locale.key}.intro`}
          key={`parent.translations.${locale.key}.intro`}
        />
        <BJMdFormItem
          control={control}
          label={`Body (${locale?.label ?? ""})`}
          fieldName={`parent.translations.${locale.key}.body`}
          key={`parent.translations.${locale.key}.body`}
        />
        <ContentSelector
          headerTitle={recommendedContentTitle}
          displayButtons={false}
          setManagedContent={setParentRecommendedContent}
          initialContent={monthInformation?.parent?.recommendedContent ?? []}
          contentListType={ContentListType.RecommendedContent}
        />
      </>
    );
  };

  const renderTryItItems = (locale: Locale) => {
    return (
      <Row gutter={6}>
        <Col span={12}>
          <Typography.Title level={5}>Try It! Check Items</Typography.Title>
          <BJButton
            size="small"
            buttonType={ButtonTypes.Save}
            style={{ marginBottom: 10 }}
            onClick={() => {
              setShowAddTryItListItem(true);
            }}
          >
            Add New Item
          </BJButton>
          <List bordered>
            {tryItListItems?.map(item => (
              <List.Item
                style={{ cursor: "pointer" }}
                onClick={() => setSelectedTryItListItem(item)}
                key={item.id}
              >
                {item?.translations?.[locale.key]?.text}
              </List.Item>
            ))}
          </List>
        </Col>
      </Row>
    );
  };

  const renderTryItList = (locale: Locale) => {
    return (
      <>
        <Row gutter={6}>
          <Col span={6}>
            <BJSelectFormItem
              control={control}
              error={!!errors?.[dateType]}
              label={capitalizeFirstLetter(dateType)}
              message={errors?.[dateType]?.message}
              optionsList={dataNumbers.map(x => ({
                key: x,
                value: x,
                display: `${x} ${convertToYears(x)}`,
              }))}
              fieldName={dateType}
              required
            />
          </Col>
          <Col span={6}>
            <BJSelectFormItem
              includeEmpty
              control={control}
              error={!!errors.tryIt?.verifierId}
              label={"Verifier"}
              message={errors.tryIt?.verifierId?.message}
              optionsList={verifiers?.map(x => ({
                key: x.id,
                value: x.id,
                display: x.name,
              }))}
              fieldName={"tryIt.verifierId"}
            />
          </Col>
          <Col span={6}>
            <BJSelectFormItem
              includeEmpty
              control={control}
              error={!!errors.tryIt?.offerId1}
              label={"Offer 1"}
              message={errors.tryIt?.offerId1?.message}
              optionsList={offers?.map(x => ({
                key: x?.id,
                value: x?.id,
                display: x?.translations?.[locale.key]?.title ?? x?.title,
              }))}
              fieldName={"tryIt.offerId1"}
            />
          </Col>
          <Col span={6}>
            <BJSelectFormItem
              includeEmpty
              control={control}
              error={!!errors.tryIt?.offerId2}
              label={"Offer 2"}
              message={errors.tryIt?.offerId2?.message}
              optionsList={offers.map(x => ({
                key: x.id,
                value: x.id,
                display: x?.translations?.[locale.key]?.title ?? x?.title,
              }))}
              fieldName={"tryIt.offerId2"}
            />
          </Col>
        </Row>
        <BJMdFormItem
          control={control}
          label={`Intro (${locale?.label ?? ""})`}
          fieldName={`tryIt.translations.${locale.key}.intro`}
          key={`tryIt.translations.${locale.key}.intro`}
        />
        <BJMdFormItem
          control={control}
          label={`Body (${locale?.label ?? ""})`}
          fieldName={`tryIt.translations.${locale.key}.body`}
          key={`tryIt.translations.${locale.key}.body`}
        />
        <ContentSelector
          headerTitle={recommendedContentTitle}
          displayButtons={false}
          setManagedContent={setTryItListRecommendedContent}
          initialContent={monthInformation?.tryIt?.recommendedContent ?? []}
          contentListType={ContentListType.RecommendedContent}
        />
        {renderTryItItems(locale)}
      </>
    );
  };

  return (
    <>
      <UpdateTryItListItemModal
        onAdd={onAddTryItListItem}
        show={selectedTryItListItem !== null || showAddTryItListItem}
        tryItListItem={selectedTryItListItem}
        onDelete={onDeleteTryItListItem}
        onHide={() => {
          setSelectedTryItListItem(null);
          setShowAddTryItListItem(false);
        }}
        onUpdate={onUpdateTryItListItem}
      />
      <FormEdit
        hasValidationErrors={Object.keys(errors).length !== 0}
        enableSave={isDirty}
        title={
          monthInformation?.translations?.[primaryLocale.key]?.monthName ??
          (monthInformation?.month
            ? `Month ${monthInformation.month}`
            : monthInformation?.week
            ? `Week ${monthInformation.week}`
            : "New Month/Week")
        }
        id={monthInformation?.id}
        editType={monthInformation?.id ? FormEditType.EDIT : FormEditType.ADD}
        loading={loading}
        onSubmit={handleSubmit(onSubmit, formValidationError)}
        onRemove={handleOnDelete}
        recordIdentifier={monthInformation?.id}
        localeSupported
        errors={errors as any}
      >
        {locale => (
          <Tabs
            defaultActiveKey="development"
            id="monthlyInformation-tabs"
            type="card"
            style={{ marginBottom: "5%" }}
          >
            <Tabs.TabPane key="development" tab="Development">
              {renderDevelopment(locale)}
            </Tabs.TabPane>
            <Tabs.TabPane key="parent" tab="Parent">
              {renderParent(locale)}
            </Tabs.TabPane>
            <Tabs.TabPane key="checklist" tab="Try It List">
              {renderTryItList(locale)}
            </Tabs.TabPane>
          </Tabs>
        )}
      </FormEdit>
    </>
  );
};
