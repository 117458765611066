import { BJAudience, BJAudienceSchema, BJAudienceValues } from "./BJAudience";
import { Divider, List, Typography } from "antd";
import styled from "styled-components";
import * as yup from "yup";
import { useFormContext } from "react-hook-form";
import { FaTimesCircle } from "react-icons/fa";
import { theme } from "../util/theme";
import _ from "lodash";
import {
  AudienceType,
  DisplayUnitType,
  getAudienceTypes,
  getAudienceTypesWithoutPartner,
} from "../../../utils";
import { BJCitySegment, BJCitySegmentSchema } from "./BJCitySegment";

export interface BJAudienceMultipleValues {
  audiences: BJAudienceValues[];
  cities: string[];
}

/**
 * @typedef {Object} BJAudienceMultipleSchema
 * @param {boolean} required - A boolean value indicating whether the audiences array is required.
 * @return {Object} The Yup schema for BJAudienceMultipleValues.
 */

export const BJAudienceMultipleSchema = (required?: boolean) => {
  return {
    audiences: required
      ? yup
          .array()
          .of(yup.object().shape(BJAudienceSchema(required)))
          .required()
      : yup
          .array()
          .of(yup.object().shape(BJAudienceSchema(required)))
          .nullable(),
    ...BJCitySegmentSchema(false),
  };
};

/**
 * Converts the extra data from Segmentation to an array of BJAudienceValues.
 * @function
 * @param {Segmentation["extra"]} extra - The value of the Segmentation "extra" property.
 * @return {BJAudienceValues[]} An array of BJAudienceValues representing the converted segments.
 */

export const convertExtraToSegments = (extra: Segmentation["extra"]) => {
  if (!extra) {
    return [];
  }

  return extra.map(item => {
    return {
      audience: item.audience as AudienceType,
      rangeUnit: item.rangeUnit as DisplayUnitType,
      range: item.range,
    };
  });
};

/**
 * Converts an array of BJAudienceValues to the extra data format used in Segmentation.
 * @function
 * @param {BJAudienceValues[]} audiences - An array of BJAudienceValues representing audiences.
 * @return {Segmentation["extra"]} The converted extra data in the Segmentation format.
 */

export const convertSegmentsToExtra = (
  audiences: BJAudienceValues[]
): Segmentation["extra"] => {
  if (!audiences) {
    return [];
  }

  return audiences.map(item => {
    return {
      audience: item.audience,
      rangeUnit: item.rangeUnit,
      range: item.range,
    };
  }) as Segmentation["extra"];
};

/**
 * The BJAudienceMultiple component is a reusable React component used for selecting and displaying multiple audiences in a form.
 * @function
 * @return {JSX.Element} A JSX element representing the BJAudienceMultiple component.
 */

export const BJAudienceMultiple = ({
  isPoll = false,
  isAllowedDate = false,
  isNewStructure = false,
  showCities = false,
  showAudience = true,
}: {
  isPoll?: boolean;
  isAllowedDate?: boolean;
  isNewStructure?: boolean;
  showCities?: boolean;
  showAudience?: boolean;
}) => {
  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<BJAudienceMultipleValues>();

  const { audiences } = watch();

  const handleAddSegment = (audience: BJAudienceValues) => {
    const { audience: audienceType, range, rangeUnit } = audience;
    setValue(
      "audiences",
      audiences
        ? [...audiences, { audience: audienceType, range, rangeUnit }]
        : [{ audience: audienceType, range, rangeUnit }],
      {
        shouldDirty: true,
        shouldValidate: true,
      }
    );
  };

  const handleRemoveAudience = (audience: BJAudienceValues) => {
    setValue(
      "audiences",
      audiences.filter(item => item !== audience),
      {
        shouldDirty: true,
        shouldValidate: true,
      }
    );
  };

  return (
    <>
      {showCities && (
        <BJCitySegment label="Cities" multiple isSelectAll size="large" />
      )}
      <Divider />
      {showAudience && (
        <>
          <BJAudience
            showAlert={true}
            isAllowedDate={isAllowedDate}
            isNewStructure={isNewStructure}
            handleSegmentSubmit={handleAddSegment}
            customAudienceList={
              isPoll ? getAudienceTypes() : getAudienceTypesWithoutPartner()
            }
          />
          {errors?.audiences && (
            <>
              {errors?.audiences?.map((error, index) => (
                <Typography.Paragraph
                  key={index}
                  type="danger"
                  className="mt-4"
                >
                  {_.capitalize(Object.values(error).join(", "))}
                </Typography.Paragraph>
              ))}
            </>
          )}
          <div>
            {!!audiences?.length && (
              <>
                <Divider />
                <List bordered>
                  <StyledListGroupItem key={"header"}>
                    <StyledGrid>
                      <StyledAudience>Audience</StyledAudience>
                      <StyledRange>Audience Range</StyledRange>
                      <StyledRemove>Remove</StyledRemove>
                    </StyledGrid>
                  </StyledListGroupItem>
                  {audiences?.map((audience, index) => (
                    <StyledListGroupItem key={index}>
                      <StyledGrid>
                        <StyledAudience>{audience.audience}</StyledAudience>
                        <StyledRange>
                          {`${audience.range || ""} ${
                            audience.rangeUnit || ""
                          }`}
                        </StyledRange>
                        <StyledRemove
                          onClick={() => handleRemoveAudience(audience)}
                        >
                          <FaTimesCircle size={16} color={theme.red} />
                        </StyledRemove>
                      </StyledGrid>
                    </StyledListGroupItem>
                  ))}
                </List>
              </>
            )}
            <Divider />
          </div>
        </>
      )}
    </>
  );
};

const StyledListGroupItem = styled(List.Item)`
  cursor: pointer;
`;
const StyledGrid = styled.div`
  display: flex;
  flex: 1;
`;

const StyledAudience = styled.div`
  width: 30%;
`;

const StyledRange = styled.div`
  width: 50%;
`;

const StyledRemove = styled.div`
  width: 20%;
  display: flex;
  justify-content: center;
`;
