import { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { Row, Col, Typography, Form } from "antd";
import { ContentCollectionsService } from "../../../services";
import {
  AspectRatio,
  DeepLinkType,
  LoyaltyFeature,
  formValidationError,
  getMaxSortOrder,
} from "../../../utils";
import { FormEdit, FormEditType } from "../../../components";
import { DropAndCrop } from "../../../components/DropAndCrop";
import { commonErrors } from "../../../language";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { BJInputFormItem, BJSwitchFormItem } from "../../../components/theme";
import { BJFormLabelInfo } from "../../../components/theme/atoms/BJInfo";
import { getDeeplinkSuffix } from "../../DeepLink/methods";
import { BJDeeplinkFormInput } from "../../../components/theme/molecules/formItems/BJDeeplinkFormInput";
import { useCountry } from "../../../context";
import { BJSelectFormItemLevel } from "../../../components/theme/molecules/formItems/BJFormSelectItemLevel";
import { BJAutoCompleteFormItem } from "../../../components/theme/molecules/formItems/BJAutoCompleteFormItem";
import { omit } from "lodash";

type FormValues = {
  icon?: string;
  imageUrl?: string;
  description: string;
  isActive: boolean;
  blurhash?: string;
  deepLink?: string;
  isLocked: boolean;
  level: number;
  translations: {
    [locale: string]: {
      description: string;
      title: string;
      deepLink?: string;
    };
  };
  loyaltyFeature?: string;
  isExperimentalTool?: boolean;
  minimumSupportedVersion?: string;
};
const { requiredError } = commonErrors;

export const ContentItemPage = ({
  contentItem,
  contentMenu,
  onUpdateDone,
  updateMenuItems,
  contentType,
}: {
  contentItem?: ContentItem;
  contentMenu?: ContentMenu;
  onUpdateDone?: () => void;
  updateMenuItems: (id: string, data: ContentMenu) => Promise<void>;
  contentType: "tools" | "content";
}) => {
  const { currentCountry, primaryLocale } = useCountry();

  const schema = yup.object().shape({
    translations: yup.object().shape(
      currentCountry?.locales.reduce((acc, item) => {
        acc[item.key] = yup.object().shape({
          title: yup.string().required(`Title ${item.label}: ${requiredError}`),
          ...(contentType === "tools" && {
            deepLink: yup
              .string()
              .required(
                `Deep link ${item.label}: ${commonErrors.requiredError}`
              )
              .url(commonErrors.urlValidationError),
          }),
        });
        return acc;
      }, {} as any)
    ),
    icon: yup
      .string()
      .required(`Menu item image: ${commonErrors.requiredError}`)
      .url(commonErrors.urlValidationError)
      .nullable(),
    ...(contentType === "content" && {
      deepLink: yup
        .string()
        .required(`Deep link: ${commonErrors.requiredError}`)
        .url(commonErrors.urlValidationError),
    }),
    level: yup.number().required(`Level: ${commonErrors.requiredError}`),
    minimumSupportedVersion: yup
      .string()
      .nullable()
      .test(
        "format-if-exists",
        "the minimum supported version must be in the format of x.x.x",
        function (value) {
          if (!value) return true;
          return /^\d+\.\d+\.\d+$/.test(value);
        }
      ),
  });
  const {
    formState: { errors, dirtyFields },
    handleSubmit,
    reset,
    control,
    setValue,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      isLocked: false,
      level: 0,
    },
  });

  const { level } = useWatch({ control });

  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    contentItem == null
      ? reset({ deepLink: "", icon: "", level: 0 })
      : reset({
          ...contentItem,
          level: contentItem?.level ?? 0,
        });

    setError(null);
  }, [contentItem, reset]);

  const copyToNotificationDeepLink = (link: string) => {
    setValue("deepLink", link, { shouldDirty: true });
  };

  const copyToNotificationDeepLinkTools = (locale: string) => {
    return (link: string) => {
      setValue(`translations.${locale}.deepLink`, link, { shouldDirty: true });
    };
  };

  const onSubmit = async (data: FormValues) => {
    const editingContentItem = contentItem?.sortOrder >= 0;
    const deepLinkSuffix = getDeeplinkSuffix(
      data.deepLink || data.translations?.[primaryLocale.key]?.deepLink
    );

    const translations: ContentItem["translations"] = {};
    for (const [key, value] of Object.entries(data.translations)) {
      translations[key] = {
        title: value.title,
        description: value.description,
        deepLink: value.deepLink,
      };
    }

    let updateData: ContentItem = {
      ...contentItem,
      icon: data.icon ?? "",
      imageUrl: data.imageUrl,
      id: deepLinkSuffix, // for analytics
      deepLink:
        data.deepLink ?? data.translations?.[primaryLocale.key]?.deepLink, // for backward compatibility
      level: data?.level ?? 0,
      isLocked: data.isLocked,
      translations,
      loyaltyFeature: data.loyaltyFeature,
      minimumSupportedVersion: data.minimumSupportedVersion,
    };

    if (data.isExperimentalTool) {
      contentMenu.menuItems = contentMenu.menuItems?.filter(
        x => x.sortOrder !== updateData.sortOrder
      );
      const maxSort = getMaxSortOrder(contentMenu?.menuItems);
      contentMenu.experimentalMenuItems = [
        ...(contentMenu?.experimentalMenuItems ?? []),
        { ...updateData, sortOrder: maxSort + 1 ?? 0 },
      ];
    } else {
      contentMenu.experimentalMenuItems =
        contentMenu.experimentalMenuItems?.filter(
          x => x.sortOrder !== updateData.sortOrder
        );
    }

    if (editingContentItem) {
      contentMenu.menuItems = contentMenu.menuItems
        ?.map(menuItem =>
          menuItem.sortOrder === updateData.sortOrder ? updateData : menuItem
        )
        .sort((a, b) => a.sortOrder - b.sortOrder);
    } else if (!data.isExperimentalTool) {
      const maxSort = getMaxSortOrder(contentMenu?.menuItems);
      updateData = { ...updateData, sortOrder: maxSort + 1 ?? 0 };
      contentMenu.menuItems = [...(contentMenu?.menuItems ?? []), updateData];
    }

    contentMenu.menuItems = contentMenu.menuItems.map(cItem =>
      omit(cItem, "isExperimentalTool")
    );

    await updateMenuItems(contentMenu.id, contentMenu);
    onUpdateDone();
  };

  const onRemove = async () => {
    if (!contentItem) return;

    const isExperimental = (
      contentItem as ContentItem & { isExperimentalTool?: boolean }
    ).isExperimentalTool;
    const filterItems = (items: ContentItem[]) =>
      items.filter(x => x.sortOrder !== contentItem.sortOrder);

    const updated = {
      ...contentMenu,
      experimentalMenuItems: isExperimental
        ? filterItems(contentMenu.experimentalMenuItems)
        : contentMenu.experimentalMenuItems,
      menuItems: filterItems(contentMenu.menuItems),
    };

    await updateMenuItems(contentMenu.id, updated);
    onUpdateDone();
  };

  const handleContentCollectionIcon = (url: string | null) => {
    setValue("icon", url, { shouldDirty: true });
  };

  const handleContentCollectionImage = (url: string | null) => {
    setValue("imageUrl", url, { shouldDirty: true });
  };

  const isDirty = !!Object.keys(dirtyFields).length;

  return (
    <FormEdit
      key={contentItem?.id?.toString()}
      error={error}
      onRemove={onRemove}
      hasValidationErrors={Object.keys(errors).length !== 0}
      enableSave={isDirty}
      subTitle={`${
        contentMenu?.translations?.[currentCountry?.abb]?.title || ""
      } `}
      id={contentItem?.id?.toString()}
      editType={
        contentItem?.sortOrder >= 0 ? FormEditType.EDIT : FormEditType.ADD
      }
      loading={false}
      onSubmit={handleSubmit(onSubmit, formValidationError)}
      deepLink={{
        type: DeepLinkType.ContentCollection,
        id: contentItem?.id?.toString(),
        builtDeeplink: contentItem?.deepLink,
        countryCode: currentCountry?.abb,
      }}
      renderButtonsOnFooter
      errors={errors as any}
    >
      <Row>
        <Col>
          {currentCountry.locales
            .sort((a, b) => (a.primary === b.primary ? 0 : b.primary ? 1 : -1))
            .map(locale => (
              <>
                {" "}
                <h3>{locale.label}</h3>
                <BJInputFormItem
                  key={`translations.${locale.key}.title`}
                  control={control}
                  fieldName={`translations.${locale.key}.title`}
                  error={!!errors?.translations?.[locale.key]?.title}
                  label={`Title (${locale.label})`}
                  message={errors?.translations?.[locale.key]?.title?.message}
                  required={true}
                  autoFocus
                />
                <BJInputFormItem
                  key={`translations.${locale?.key}.description`}
                  rows={3}
                  control={control}
                  error={
                    !!errors?.translations?.[locale?.key]?.description?.message
                  }
                  label={`Description (${locale?.label})`}
                  message={
                    errors?.translations?.[locale?.key]?.description?.message
                  }
                  fieldName={`translations.${locale?.key}.description`}
                />
              </>
            ))}

          <Form.Item
            required
            label={
              <BJFormLabelInfo
                info={"Please upload 200x200 size png"}
                label={"Menu item image"}
              />
            }
            validateStatus={errors.icon && "error"}
            help={
              <Typography.Paragraph type="danger">
                {errors.icon?.message}
              </Typography.Paragraph>
            }
          >
            <DropAndCrop
              allowNaturalImageUpload={false}
              croppable
              title="Menu item image"
              initialUrl={contentItem?.icon}
              setUploadUrl={handleContentCollectionIcon}
              uploadImage={
                ContentCollectionsService.uploadContentCollectionImage
              }
              accept={[".png"]}
              lockedRatio={AspectRatio.OneToOne}
              defaultCropBoxWidth={100}
              defaultCropBoxHeight={100}
            />
          </Form.Item>
          <Form.Item
            required
            label={
              <BJFormLabelInfo
                info={
                  "This image will be displayed in content collections, recommended content and polls"
                }
                label={"Cover image"}
              />
            }
            validateStatus={errors.imageUrl && "error"}
            help={
              <Typography.Paragraph type="danger">
                {errors.imageUrl?.message}
              </Typography.Paragraph>
            }
          >
            <DropAndCrop
              allowNaturalImageUpload={false}
              croppable
              title="Cover image"
              initialUrl={contentItem?.imageUrl}
              setUploadUrl={handleContentCollectionImage}
              uploadImage={
                ContentCollectionsService.uploadContentCollectionImage
              }
              accept={[".png"]}
              lockedRatio={AspectRatio.FourToThree}
              defaultCropBoxWidth={300}
            />
          </Form.Item>

          <BJSelectFormItemLevel
            control={control}
            error={!!errors?.level}
            message={errors?.level?.message}
            fieldName="level"
          />

          {level && (
            <BJAutoCompleteFormItem
              label="Loyalty feature"
              control={control}
              name={"loyaltyFeature"}
              options={Object.values(LoyaltyFeature)}
              size="large"
            />
          )}

          {contentType === "tools" ? (
            currentCountry.locales
              .sort((a, b) =>
                a.primary === b.primary ? 0 : b.primary ? 1 : -1
              )
              .map(locale => (
                <BJDeeplinkFormInput
                  key={`translations.${locale.key}.deepLink`}
                  control={control}
                  error={!!errors?.translations?.[locale.key]?.deepLink}
                  label={`Deep link (${locale.label})`}
                  message={
                    errors?.translations?.[locale.key]?.deepLink?.message
                  }
                  required
                  fieldName={`translations.${locale.key}.deepLink`}
                  title="Content"
                  copyToNotificationDeepLink={copyToNotificationDeepLinkTools(
                    locale.key
                  )}
                />
              ))
          ) : (
            <BJDeeplinkFormInput
              control={control}
              error={!!errors.deepLink}
              label={"Deep link"}
              message={errors.deepLink?.message}
              required
              fieldName={"deepLink"}
              title="Content"
              copyToNotificationDeepLink={copyToNotificationDeepLink}
            />
          )}

          <BJInputFormItem
            control={control}
            label={"Minimum supported version"}
            fieldName={"minimumSupportedVersion"}
            extra={
              "if not set it will be visible to all users, Please use x.xx.xx formart for version"
            }
          />

          <BJSwitchFormItem
            control={control}
            label={"is Locked"}
            fieldName={"isLocked"}
            extra={
              "This Working 2.31.0 onwards, Test Users won't see when isLocked is true"
            }
          />

          <BJSwitchFormItem
            control={control}
            label={"is Experimental Tool"}
            fieldName={"isExperimentalTool"}
            extra={
              "Please note that if this is disabled, the tool will be visible to all users"
            }
          />
        </Col>
      </Row>
    </FormEdit>
  );
};
