import React, { createContext, useEffect, useMemo, useState } from "react";
import { CountryService } from "../services/CountryService";
import { useCountry } from "./CountryContext";

interface SymptomsState {
  symptoms: Symptom[];
  loading: boolean;
}

const SymptomsContext = createContext<SymptomsState>({
  symptoms: [],
  loading: true,
});

export const SymptomsProvider = ({ ...rest }) => {
  const [symptoms, setSymptoms] = useState<Symptom[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const { currentCountry } = useCountry();

  useEffect(() => {
    setLoading(true);
    const unsub = CountryService.subscribeSymptoms((_error, _symptoms) => {
      setSymptoms(_symptoms);
      setError(_error);
      setLoading(false);
    }, currentCountry?.abb);

    return unsub;
  }, [currentCountry?.abb]);

  const value = useMemo(() => ({ symptoms, loading }), [symptoms, loading]);

  return <SymptomsContext.Provider value={value} {...rest} />;
};

export const useSymptoms = () => {
  const context = React.useContext(SymptomsContext);
  if (context === undefined) {
    throw new Error("useSymptoms must be used within a SymptomsProvider");
  }
  return context;
};
