import { useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { ErrorFallback } from "../components";

import { Outlet } from "react-router-dom";
import { useReports } from "../hooks/useReports";
import { Layout } from "antd";
import { Content } from "antd/lib/layout/layout";
import styled from "styled-components";
import { BJContainer } from "../components/theme";
import SiderNavigationBar from "../components/theme/components/SiderNavBar";

import { Dashboard, Offers, FAQ, Influencer } from "../assets/icons/icons";
import * as BiIcons from "react-icons/bi";
import * as GiIcons from "react-icons/gi";
import * as FaIcons from "react-icons/fa";
import * as PiIcons from "react-icons/pi";
import * as IoIcons from "react-icons/io5";
import * as MdIcons from "react-icons/md";
import * as BsIcons from "react-icons/bs";
import * as VscIcons from "react-icons/vsc";
import { IconType } from "react-icons";

export const AnalyticsLayout = () => {
  const { reports, menus } = useReports();
  const navigate = useNavigate();

  const iconLibraries: Record<
    string,
    Record<string, IconType> | { [key: string]: IconType }
  > = {
    bi: BiIcons,
    gi: GiIcons,
    fa: FaIcons,
    pi: PiIcons,
    io5: IoIcons,
    md: MdIcons,
    bs: BsIcons,
    vsc: VscIcons,
    custom: {
      Dashboard,
      Offers,
      FAQ,
      Influencer,
    },
  };

  const getIcon = (iconPath: string) => {
    if (!iconPath) return <MdIcons.MdDataUsage />;
    const [lib, iconName] = iconPath.split("/");
    const IconComponent = iconLibraries[lib]?.[iconName];
    return IconComponent ? <IconComponent /> : <MdIcons.MdDataUsage />;
  };

  const createMenuItems = () =>
    menus.map(menu => ({
      key: menu.id,
      displayName: menu.title,
      url: `/analytics/${menu.id}`,
      icon: getIcon(menu.icon),
      subMenuKey: menu.id,
      subMenu: reports
        .filter(report => report.parentKey === menu.id)
        .map(report => ({
          key: report.id,
          value: `${report.id}`,
          displayName: report.title,
        })),
    }));

  const createReportsWithoutMenu = () =>
    reports
      .filter(report => !report.parentKey)
      .map(report => ({
        key: report.id,
        displayName: report.title,
        url: `/analytics/${report.id}`,
        icon: getIcon(report.icon),
      }));

  return (
    <Layout>
      <StyledLayout>
        <SiderNavigationBar
          items={[...createMenuItems(), ...createReportsWithoutMenu()]}
        />
        <StyledLayoutContent>
          <Content>
            <BJContainer>
              <Sentry.ErrorBoundary
                fallback={ErrorFallback}
                onReset={() => navigate("./")}
              >
                <Outlet />
              </Sentry.ErrorBoundary>
            </BJContainer>
          </Content>
        </StyledLayoutContent>
      </StyledLayout>
    </Layout>
  );
};
const StyledLayout = styled(Layout)`
  background-color: ${props => props.theme.white};
`;

const StyledLayoutContent = styled(Layout)`
  padding: 1rem 1rem 1rem;
  margin-bottom: 2%;
`;
