import { Navigate, Route, Routes } from "react-router-dom";
import { AnalyticsLayout } from "../../Layouts";
import { ReportContent } from "../../pages/Admin/Reports/ReportContent";

export const AnalyticsRoutes = () => (
  <Routes>
    <Route path="/" element={<AnalyticsLayout />}>
      <Route path=":menuId/:reportId" element={<ReportContent />} />
      <Route path=":reportId" element={<ReportContent />} />
    </Route>
    <Route path="*" element={<Navigate to="/analytics" />} />
  </Routes>
);
