import { useEffect, useState, useMemo } from "react";
import { commonErrors } from "../../language";
import { CommonContentType, getMax } from "../../utils";

// Type guard function to check if a property is defined
const isDefined = <T>(property: T | undefined): property is T => {
  return property !== undefined;
};

export const commonContentV2ToCommonContent = (
  oldValue: CommonContentV2 & CommonContent,
  primaryLocale: string
): CommonContent => {
  const { translations, ...rest } = oldValue;

  return {
    ...rest,
    ...(translations?.[primaryLocale] ?? {}),
  };
};

export const transformToCommonContent = <
  T extends CommonContentV2 & { url?: string; image?: string }
>(
  source: T,
  sortOrder: number,
  contentType: CommonContentType
) => {
  const transform: CommonContentV2 = {
    id: source.id,
    sortOrder: ++sortOrder,
    type: contentType,
    translations: {},
  };

  if (source.url && contentType === CommonContentType.BLOG) {
    transform.blogPostUrl = source.url;
  }
  if (isDefined(source.podcastId)) {
    transform.podcastId = source.podcastId;
  }
  if (isDefined(source.blurhash)) {
    transform.blurhash = source?.blurhash;
  }

  if (isDefined(source.imageBlurhash)) {
    transform.imageBlurhash = source?.imageBlurhash;
  }

  if (isDefined(source.imageUrl) || isDefined(source.image)) {
    transform.imageUrl = source.imageUrl || source.image;
  }
  //TODO: remove following stretchedImageUrl and squareImageUrl after force update 2.35.0
  if (isDefined(source.stretchedImageUrl)) {
    transform.stretchedImageUrl = source.stretchedImageUrl;
  }
  if (isDefined(source.squareImageUrl)) {
    transform.squareImageUrl = source.squareImageUrl;
  }

  if (isDefined(source.name)) {
    transform.name = source.name;
  }
  if (isDefined(source.blogPostUrl)) {
    transform.blogPostUrl = source.blogPostUrl;
  }
  if (isDefined(source.restricted)) {
    transform.restricted = source.restricted;
  }
  if (isDefined(source.company)) {
    transform.company = source.company;
  }
  if (isDefined(source.contentText)) {
    transform.contentText = source.contentText;
  }
  if (isDefined(source.verifier)) {
    transform.verifier = source.verifier;
  }
  if (isDefined(source.sponsor)) {
    transform.sponsor = source.sponsor;
  }
  if (isDefined(source.level)) {
    transform.level = source.level;
  }
  if (isDefined(source.cities)) {
    transform.cities = source.cities;
  }

  if (source.translations) {
    const translationKeysArray: (keyof CommonContentV2["translations"][string])[] =
      [
        "duration",
        "title",
        "description",
        "answer",
        "question",
        "name",
        "body",
        "intro",
        "blurhash",
        "contentText",
        "imageUrl",
        "stretchedImageUrl",
        "squareImageUrl",
        "squareBlurhash",
        "imageBlurhash",
        "stretchedBlurhash",
      ];

    Object.entries(source.translations).forEach(([key, value]) => {
      const { description, intro, ...rest } = value;
      const transformData: CommonContentV2["translations"][string] = {
        ...rest,
        description: description || intro,
      };

      const filteredTransformData = Object.fromEntries(
        translationKeysArray
          .filter(key => key in transformData)
          .map(key => [key, transformData[key]])
      );

      transform.translations[key] = filteredTransformData;
    });
  }
  return transform;
};

export const useFilterContent = <T extends { id?: string; url?: string }>(
  selectedContent: CommonContentV2[],
  maxNoOfContentLimit: number,
  contentType: CommonContentType,
  datasource: T[]
) => {
  const [selectedContentCollections, setSelectedContentCollections] = useState<
    T[]
  >([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const initiallySelectedContentList = datasource.filter(({ id }) =>
      selectedContent.some(item => item.id === id)
    );
    setSelectedContentCollections(initiallySelectedContentList);
  }, [datasource, selectedContent]);

  const updatedContent = useMemo(() => {
    let currentSortOrder = getMax(selectedContent, "sortOrder") ?? 1;
    const contentExceptContentCollections = selectedContent.filter(
      item => item.type !== contentType
    );
    const selectedContentCollectionTypeContent = selectedContentCollections.map(
      source => {
        const content = selectedContent.find(x => x.id === source.id);
        if (content) {
          return content;
        } else {
          return transformToCommonContent(
            source as unknown as CommonContent,
            ++currentSortOrder,
            contentType
          );
        }
      }
    );

    return [
      ...contentExceptContentCollections,
      ...selectedContentCollectionTypeContent,
    ];
  }, [selectedContent, selectedContentCollections, contentType]);

  useEffect(() => {
    setError(
      maxNoOfContentLimit < updatedContent.length
        ? commonErrors.contentLimitError
        : null
    );
  }, [maxNoOfContentLimit, updatedContent]);

  return {
    error,
    updatedContent,
    setError,
    selectedContentCollections,
    setSelectedContentCollections,
    datasource,
  };
};
