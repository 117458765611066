export const validationError = "validationError";
export const cloudFunctionRegion = "europe-west2";
export const cloudDomainUrl = `https://${cloudFunctionRegion}-${process.env.REACT_APP_PROJECT_ID}.cloudfunctions.net/`;
export const deeplinkBaseUrl = process.env.REACT_APP_DEEPLINK_BASE_URL;
export const dynamicLinkBaseUrl = process.env.REACT_APP_DYNAMICLINK_BASE_URL;
export const oneLinkBaseUrl = {
  DEVELOPMENT: {
    "App-to-app": "https://dev.link.babyjourney.se/SoRu/9xr01box",
    "Email-to-app": "https://dev.link.babyjourney.se/SoRu/8pkse5er",
    "Social-to-app": "https://dev.link.babyjourney.se/SoRu/auikizx5",
  },
  STAGING: {
    "App-to-app": "https://staging.link.babyjourney.se/SoRu/qdbdm7nd",
    "Email-to-app": "https://staging.link.babyjourney.se/SoRu/6qrvosge",
    "Social-to-app": "https://staging.link.babyjourney.se/SoRu/gdvi8zj7",
  },
  PRODUCTION: {
    "App-to-app": "https://link.babyjourney.se/SoRu/hm3qa27r",
    "Email-to-app": "https://link.babyjourney.se/SoRu/vu4d1iqc",
    "Social-to-app": "https://link.babyjourney.se/SoRu/vvds1t4h",
  },
};
export const offerPriorityUptoCount = 5;
export const recommendedContentTitle = "Recommended content";
export const cloudFunctionNames = {
  getUser: "getUser",
  updateUserEmail: "updateUserEmail",
  sendBirthPlanV2: "sendBirthPlanV2",
  aitranslator: "aitranslator",
  deleteUser: "deleteUser",
  resetChecklist: "resetChecklist",
  scrapeMeta: "scrapeMeta",
  getUserForClaims: "getUserForClaims",
  updateBackofficeUser: "updateBackofficeUser",
  createBackofficeUser: "createBackofficeUser",
  deleteBackofficeUser: "deleteBackofficeUser",
  deleteInfluencer: "deleteInfluencer",
  tags: {
    addTag: "addTag",
    deleteTag: "deleteTag",
    updateTag: "updateTag",
    generateTags: "generateAiTagwords",
    translateTags: "translateTagwords",
  },
  faqs: {
    updateFaqSortOrder: "updateFaqSortOrder",
  },
  transcriptions: {
    generateTranscription: "audioTranscription",
  },
  doula: {
    contentEmbedding: "doulaContentEmbedding",
    articleEmbedding: "embedAllArticles",
    getTools: "getDoulaTools",
    updateTools: "updateDoulaTools",
  },
};

export const onboarding = "onboarding";

export const siteConsts = {
  sentryDsn:
    "https://4e1718c46d18441ebf4850c8ac534db7@o520608.ingest.sentry.io/4504099450191872",
  scrapUrl: `${cloudDomainUrl}${cloudFunctionNames.scrapeMeta}?url=`,
  getUserUrl: (userId: string) =>
    `${cloudDomainUrl}/${cloudFunctionNames.getUser}?uid=${userId}`,
  deleteUserUrl: (userId: string) =>
    `${cloudDomainUrl}/${cloudFunctionNames.deleteUser}?uid=${userId}`,
  youtubeApiV3: (youtubeUrlId: string) =>
    `https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${youtubeUrlId}&key=${process.env.REACT_APP_YOUTUBE_API_KEY}`,
};

export const firebaseBucketConsts = {
  articleFileStoragePath: "articles",
  highlightFileStoragePath: "highlight",
  tips: "tips",
  articleVideoFileStoragePath: "Article video h265/",
  videoExtention: "mp4",
  remoteNotifications: "remote notifications",
};

export const firebaseCollectionNames = {
  admins: "admins",
  articleCategories: "articleCategories",
  articles: "articles",
  birthStories: "birthStories",
  blogposts: "blogposts",
  categories: "categories",
  checklistTemplates: "checklistTemplates",
  checklists: "checklists",
  childBlogPosts: "childBlogPosts",
  childInformation: "childInformation",
  contentMenu: "contentMenu",
  tipsCollection: {
    tips: "tips",
    pregnancyTipsSub: "pregnancyTips",
    childTipsSub: "childTips",
  },
  content: {
    content: "content",
    editorContent: "editorContent",
    pregnancyTipsSub: "pregnancyTips",
  },
  dietaryAdviceCategories: "dietaryAdviceCategories",
  dietaryAdviceContent: "dietaryAdviceContent",
  dietaryAdvices: "dietaryAdvices",
  faq: "faq",
  faqCategories: "faqCategories",
  forceUpdate: "forceUpdate",
  influencers: "influencers",
  milestones: "milestones",
  monthlyInformation: "monthlyInformation",
  offerSignups: "offerSignups",
  offers: "offers",
  podcasts: "podcasts",
  popular: "popular",
  popularArticles: "popularArticles",
  search: "search",
  shareCodes: "shareCodes",
  Sponsors: "Sponsors",
  support: "support",
  users: "users",
  verifiers: "verifiers",
  weeklyInformation: "weeklyInformation",
  highlights: "highlights",
  generalBlogPosts: "generalBlogPosts",
  categoryItems: {
    categoryItems: "categoryItems",
    faq: "faq",
    content: "content",
  },
  notifications: {
    collectionName: "notifications",
    content: {
      pregnancy: "pregnancy",
      children: "children",
    },
  },
  remoteNotifications: {
    collectionName: "remoteNotifications",
  },
  tagsCollection: {
    tags: "tags",
    commonSub: "common",
  },
  stories: "stories",
  transcriptionsCollection: {
    transcriptions: "transcriptions",
    commonSub: "common",
  },
  ragCorpus: "ragCorpus",
};

export const NUM_OF_WEEKS = 42;
export const NUM_OF_MONTHS = 24;
export const NUM_OF_DAYS_FOR_PREG = 301;
export const NUM_OF_DAYS_FOR_CHILD = 720;
export const NUM_OF_WEEKS_FOR_CHILD = 105;
export const WEEK_STARTING_KEY_FIRESTORE = 24;
export const HALF_YEARLY_MONTHS = [30, 36, 42, 48, 54, 60];

export const TAGS_MAX_LENGTH = 5;
export const UPLODABLE_IMAGE_SIZE = 2; //in MB

export const scraperMessages = {
  limitOver: "Limit exceeded for scraper",
  privateAccount: "Cannot access private accounts",
};

export const deepLinkPrefix = {
  PRODUCTION: {
    env: "Production",
    domain: "https://babyjourney.se",
  },
  DEVELOPMENT: {
    env: "Development",
    domain: "https://dev.babyjourney.se",
  },
  STAGING: {
    env: "Staging",
    domain: "https://staging.babyjourney.se",
  },
};

export const APP_STORE_ID = "1515225502";

export const SKIP_APP_PREVIEW_PAGE = 1;

export const IOS_BUNDLE_ID = "se.babyjourney";

export const ANDROID_PACKAGE_NAME = "se.babyjourney";
