import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useContext,
} from "react";
import firebase from "../firebase";

import {
  MonthlyInformationService,
  VerifierService,
  OffersService,
  MonthInfoMetaData,
} from "../services";
import { getMonths } from "../utils/timeUtils";
import { useCountry } from "./CountryContext";

type ContextState = {
  monthlyInformation: MonthInformation[];
  weeklyInformation: MonthInformation[];
  loading: boolean;
  error: Error | null;
  getById: (id: MonthInformation["id"]) => MonthInformation | null;
  updateMonthlyInformation: (data: MonthInformation) => Promise<void>;
  updateMonthlyInfoMetadata: (data: MonthInfoMetaData) => Promise<void>;
  deleteMonthlyInfoMetadata: (data: MonthInfoMetaData) => Promise<void>;
  createMonthlyInformation: (
    data: MonthInformation
  ) => Promise<firebase.firestore.DocumentReference | null>;
  deleteMonthlyInformation: (id: string) => Promise<void>;
};

const MonthlyInformationContext = createContext<ContextState>({
  monthlyInformation: [],
  weeklyInformation: [],
  loading: false,
  error: null,
  getById: () => null,
  updateMonthlyInformation: async () => null,
  updateMonthlyInfoMetadata: async () => null,
  createMonthlyInformation: async () => null,
  deleteMonthlyInformation: async () => null,
  deleteMonthlyInfoMetadata: async () => null,
});

export const MonthlyInformationProvider = ({ ...rest }) => {
  const [monthlyInformationData, setMonthlyInformationData] = useState<
    MonthInformation[]
  >([]);
  const [monthlyInformation, setMonthlyInformation] = useState<
    MonthInformation[]
  >([]);
  const [weeklyInformation, setWeeklyInformation] = useState<
    MonthInformation[]
  >([]);
  const [offersData, setOffersData] = useState<OfferV2[]>([]);
  const [verifiersData, setVerifiersData] = useState<Verifier[]>([]);
  const [loadingMonthlyInformation, setLoadingMonthlyInformation] =
    useState(true);
  const [loadingOffers, setLoadingOffers] = useState(true);
  const [loadingVerifiers, setLoadingVerifiers] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const { currentCountry } = useCountry();

  const verifierService = useMemo(
    () => new VerifierService(currentCountry?.abb),
    [currentCountry?.abb]
  );

  const monthlyInformationService = useMemo(
    () => new MonthlyInformationService(currentCountry?.abb),
    [currentCountry?.abb]
  );

  const offersService = useMemo(
    () => new OffersService(currentCountry?.abb),
    [currentCountry?.abb]
  );

  useEffect(() => {
    setLoadingMonthlyInformation(true);
    setLoadingOffers(true);
    setLoadingVerifiers(true);

    const unsubscribeMonthlyInformation = monthlyInformationService.subscribe(
      (error, monthlyInformation) => {
        setMonthlyInformationData(monthlyInformation);
        setError(error);
        setLoadingMonthlyInformation(false);
      }
    );

    const unsubscribeOffers = offersService.subscribe((error, offers) => {
      setOffersData(offers);
      setError(error);
      setLoadingOffers(false);
    });

    const unsubscribeVerifier = verifierService.subscribe(
      (error, verifiers) => {
        setVerifiersData(verifiers);
        setError(error);
        setLoadingVerifiers(false);
      }
    );

    return () => {
      unsubscribeMonthlyInformation();
      unsubscribeOffers();
      unsubscribeVerifier();
    };
  }, [monthlyInformationService, offersService, verifierService]);

  const updateMonthlyInformation = useCallback(
    async (data: MonthInformation) =>
      await monthlyInformationService.update(data),
    [monthlyInformationService]
  );

  const updateMonthlyInfoMetadata = useCallback(
    async (data: MonthInfoMetaData) =>
      await monthlyInformationService.updateMetadata(data),
    [monthlyInformationService]
  );

  const deleteMonthlyInfoMetadata = useCallback(
    async (data: MonthInfoMetaData) =>
      await monthlyInformationService.deleteMetadata(data),
    [monthlyInformationService]
  );

  const createMonthlyInformation = useCallback(
    async (data: MonthInformation) =>
      await monthlyInformationService.create(data),
    [monthlyInformationService]
  );

  const deleteMonthlyInformation = useCallback(
    async (id: string) => await monthlyInformationService.delete(id),
    [monthlyInformationService]
  );

  const getCommonInformation = (monthInformation: MonthInformation) => {
    let { development, parent, tryIt } = monthInformation;

    if (typeof development === "undefined") {
      development = {
        offerId1: "",
        offerId2: "",
        offer1: null,
        offer2: null,
        verifierId: "",
        verifier: null,
      };
    } else {
      development.offer1 =
        offersData.find(x => x.id === development.offerId1) ?? null;
      development.offer2 =
        offersData.find(x => x.id === development.offerId2) ?? null;
      development.verifier =
        verifiersData.find(x => x.id === development.verifierId) ?? null;
    }

    if (typeof parent === "undefined") {
      parent = {
        offerId1: "",
        offerId2: "",
        offer1: null,
        offer2: null,
        verifierId: "",
        verifier: null,
      };
    } else {
      parent.offer1 = offersData.find(x => x.id === parent.offerId1) ?? null;
      parent.offer2 = offersData.find(x => x.id === parent.offerId2) ?? null;
      parent.verifier =
        verifiersData.find(x => x.id === parent.verifierId) ?? null;
    }

    if (typeof tryIt === "undefined") {
      tryIt = {
        offerId1: "",
        offerId2: "",
        offer1: null,
        offer2: null,
        verifierId: "",
        verifier: null,
        items: [],
      };
    } else {
      tryIt.offer1 = offersData.find(x => x.id === tryIt.offerId1) ?? null;
      tryIt.offer2 = offersData.find(x => x.id === tryIt.offerId2) ?? null;
      tryIt.verifier =
        verifiersData.find(x => x.id === tryIt.verifierId) ?? null;
    }

    return { development, parent, tryIt };
  };

  useEffect(() => {
    if (loadingMonthlyInformation || loadingOffers || loadingVerifiers) {
      return;
    }

    const monthsInformation = monthlyInformationData.filter(
      monthlyInfo => monthlyInfo.month
    );
    const weeksInformation = monthlyInformationData.filter(
      monthlyInfo => monthlyInfo.week
    );

    const monthlyInformation: MonthInformation[] = monthsInformation.map(
      monthlyInformation => {
        const { month } = monthlyInformation;
        const { findNameByMonthNumber } = getMonths();
        const monthName = findNameByMonthNumber(month) ?? null;
        const { development, parent, tryIt } =
          getCommonInformation(monthlyInformation);

        return {
          ...monthlyInformation,
          development,
          parent,
          tryIt,
          monthName,
        };
      }
    );

    const weeklyInformation: MonthInformation[] = weeksInformation.map(
      monthlyInformation => {
        const { week } = monthlyInformation;
        const { development, parent, tryIt } =
          getCommonInformation(monthlyInformation);

        return {
          ...monthlyInformation,
          development,
          parent,
          tryIt,
          week,
        };
      }
    );

    setMonthlyInformation(monthlyInformation);
    setWeeklyInformation(weeklyInformation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    loadingMonthlyInformation,
    loadingOffers,
    loadingVerifiers,
    monthlyInformationData,
    offersData,
    verifiersData,
  ]);

  const getById = useCallback(
    (id: MonthInformation["id"]) => {
      const weeklyAndMonthlyInformation = [
        ...weeklyInformation,
        ...monthlyInformation,
      ];
      const information = weeklyAndMonthlyInformation.find(a => a.id === id);
      return information || null;
    },
    [monthlyInformation, weeklyInformation]
  );

  const loading =
    loadingMonthlyInformation || loadingOffers || loadingVerifiers;

  const value = useMemo(
    () => ({
      monthlyInformation,
      weeklyInformation,
      loading,
      error,
      getById,
      updateMonthlyInformation,
      updateMonthlyInfoMetadata,
      createMonthlyInformation,
      deleteMonthlyInformation,
      deleteMonthlyInfoMetadata,
    }),
    [
      monthlyInformation,
      weeklyInformation,
      loading,
      error,
      getById,
      updateMonthlyInformation,
      updateMonthlyInfoMetadata,
      createMonthlyInformation,
      deleteMonthlyInformation,
      deleteMonthlyInfoMetadata,
    ]
  );

  return <MonthlyInformationContext.Provider value={value} {...rest} />;
};

export const useMonthlyInformation = () => {
  const context = useContext(MonthlyInformationContext);
  if (!context) {
    throw new Error(
      "useMonthlyInformation must be used within an MonthlyInformationProvider"
    );
  }
  return context;
};

export const useMonthInformation = (
  monthlyInformationId: MonthInformation["id"]
) => {
  const [monthOrWeekInformation, setMonthOrWeekInformation] =
    useState<MonthInformation | null>(null);
  const [loading, setLoading] = useState(true);

  const {
    getById,
    loading: loadingMonthlyInformation,
    ...rest
  } = useMonthlyInformation();

  useEffect(() => {
    if (loadingMonthlyInformation) {
      return;
    }

    setMonthOrWeekInformation(getById(monthlyInformationId));
    setLoading(false);
  }, [loadingMonthlyInformation, getById, monthlyInformationId]);

  return { monthInformation: monthOrWeekInformation, loading, ...rest };
};
