import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import {
  Row,
  Col,
  Typography,
  Form,
  DatePicker,
  Switch,
  Space,
  Divider,
  List,
} from "antd";
import { useCompetition, useCompetitions, useCountry } from "../../context";
import {
  AspectRatio,
  formatDateStringToTimeStamp,
  formValidationError,
  isEqualArrays,
} from "../../utils";
import { FormEdit, FormEditType } from "../../components";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  BJInputFormItem,
  BJSelectFormItemLevel,
  ButtonTypes,
} from "../../components/theme";
import moment from "moment";
import { addCompetition, cms } from "../../routes/routeConsts";
import { UpdateQuestionModal } from "./UpdateQuestionModal";
import BJButton from "../../components/theme/atoms/Button";
import styled from "styled-components";
import { commonErrors } from "../../language";
import { DropAndCrop } from "../../components/DropAndCrop";
import { BJMdFormItem } from "../../components/theme/molecules/formItems/BJFormMarkdown";

type FormValues = {
  translations?: {
    [locale: string]: CompetitionInfo;
  };
  isActive: boolean;
  startEnd?: [any, any]; //design limitation for complex objects in react-hook forms...
  level?: number;
};

type UploadProps = {
  locale: string;
  url: string;
  fieldName: string;
};

const { requiredError } = commonErrors;
const { RangePicker } = DatePicker;

export const CompetitionPage = () => {
  const navigate = useNavigate();
  const { id } = useParams<string>();
  const {
    createCompetition,
    updateCompetition,
    deleteCompetition,
    uploadCompetitionImage,
  } = useCompetitions();
  const { competition, loading: loadingCompetitions } = useCompetition(id);
  const [showAddQuestion, setShowAddQuestion] = useState(false);
  const [selectedQuestion, setSelectedQuestion] =
    useState<CompetitionQuestion | null>(null);
  const [questions, setQuestions] = useState<CompetitionQuestion[]>([]);
  const { currentCountry, primaryLocale } = useCountry();

  const schema = yup.object().shape({
    translations: yup.object().shape(
      currentCountry?.locales.reduce((acc, item) => {
        acc[item.key] = yup.object().shape({
          title: yup
            .string()
            .required(
              `Title (${String(item.key).toUpperCase()}): ${requiredError}`
            ),
        });
        return acc;
      }, {} as any)
    ),
  });

  const {
    formState: { errors, dirtyFields },
    handleSubmit,
    reset,
    control,
    watch,
    getValues,
    setValue,
  } = useForm<FormValues>({ resolver: yupResolver(schema) });

  useEffect(() => {
    if (loadingCompetitions || competition === null) {
      return;
    }
    const startTime = competition.startDate
      ? moment(competition.startDate)
      : null;
    const endTime = competition.endDate ? moment(competition.endDate) : null;
    setQuestions(competition?.questions);
    reset({
      ...competition,
      translations: competition?.translations ?? {},
      startEnd: [startTime, endTime],
      level: competition.level ?? 0,
    });
  }, [competition, reset, loadingCompetitions]);

  const onSubmit = async (data: FormValues) => {
    const startDate = data.startEnd?.[0]
      ? formatDateStringToTimeStamp(data.startEnd?.[0].toString(), false)
      : null;
    const endDate = data.startEnd?.[1]
      ? formatDateStringToTimeStamp(data.startEnd?.[1].toString(), false)
      : null;
    const translations: Competition["translations"] = {};

    for (const [key, value] of Object.entries(data.translations)) {
      translations[key] = {
        title: value.title,
        description: value.description,
        subTitle: value.subTitle,
        imageUrl: value.imageUrl,
        blurhash: value.blurhash,
      };
    }
    const updateData: Competition = {
      image: data.translations[primaryLocale.key]?.imageUrl ?? "",
      blurhash: data.translations[primaryLocale.key]?.blurhash ?? "",
      questions: questions,
      translations,
      isActive: data.isActive,
      startDate: startDate ?? null,
      endDate: endDate ?? null,
      level: data.level,
    };

    if (competition) {
      await updateCompetition(competition.id, updateData);
    } else {
      const { id } = await createCompetition(updateData);
      return navigate(`../${id}`);
    }
  };

  const onRemove = async () => {
    if (competition) {
      await deleteCompetition(competition.id);
      return navigate(`${cms}/${addCompetition}`);
    }
  };

  const handleCompetitionImageUrl = ({
    locale,
    url,
    fieldName,
  }: UploadProps) => {
    const temp = { ...getValues("translations") };

    temp[locale] = {
      ...temp[locale],
      [fieldName]: url,
    };

    if (locale) {
      temp["en"] = {
        ...temp["en"],
        [fieldName]: url,
      };
    }

    setValue(`translations`, temp, { shouldDirty: true });
  };

  const isDirty =
    !!Object.keys(dirtyFields).length ||
    !isEqualArrays(competition?.questions ?? [], questions);

  const onAddQuestion = (Question: CompetitionQuestion) => {
    setQuestions(old => [...old, Question]);
    setShowAddQuestion(false);
  };

  const onUpdateQuestion = (Question: CompetitionQuestion) => {
    setQuestions(old => old.map(t => (t.id === Question.id ? Question : t)));
    setSelectedQuestion(null);
  };

  const onDeleteQuestion = (id: Checklist["id"]) => {
    setQuestions(old => old.filter(t => t.id !== id));
    setSelectedQuestion(null);
  };

  return (
    <>
      <UpdateQuestionModal
        onAdd={onAddQuestion}
        show={selectedQuestion !== null || showAddQuestion}
        question={selectedQuestion}
        onDelete={onDeleteQuestion}
        onHide={() => {
          setSelectedQuestion(null);
          setShowAddQuestion(false);
        }}
        onUpdate={onUpdateQuestion}
      />
      <FormEdit
        backRoutePath={`${cms}/${addCompetition}`}
        onRemove={onRemove}
        hasValidationErrors={Object.keys(errors).length !== 0}
        enableSave={isDirty}
        title={
          competition
            ? competition?.translations?.[primaryLocale.key]?.title
            : "New Competition"
        }
        id={competition?.id}
        editType={competition?.id ? FormEditType.EDIT : FormEditType.ADD}
        loading={loadingCompetitions}
        onSubmit={handleSubmit(onSubmit, formValidationError)}
        recordIdentifier={competition?.id}
        localeSupported
        errors={errors as any}
      >
        {locale => (
          <>
            <Row gutter={{ md: 20 }}>
              <Col md={24} lg={12}>
                <BJInputFormItem
                  label={`Title (${locale?.label ?? ""})`}
                  fieldName={`translations.${locale.key}.title`}
                  key={`translations.${locale.key}.title`}
                  control={control}
                  error={!!errors?.translations?.[locale.key]?.title}
                  message={errors?.translations?.[locale.key]?.title?.message}
                  rows={2}
                  required
                  autoFocus
                />

                <BJInputFormItem
                  label={`Sub title (${locale?.label ?? ""})`}
                  fieldName={`translations.${locale.key}.subTitle`}
                  key={`translations.${locale.key}.subTitle`}
                  control={control}
                  error={!!errors?.translations?.[locale.key]?.subTitle}
                  message={
                    errors?.translations?.[locale.key]?.subTitle?.message
                  }
                  rows={2}
                />

                <BJMdFormItem
                  label={`Description (${locale?.label ?? ""})`}
                  fieldName={`translations.${locale.key}.description`}
                  key={`translations.${locale.key}.description`}
                  control={control}
                  error={!!errors?.translations?.[locale.key]?.description}
                  message={
                    errors?.translations?.[locale.key]?.description?.message
                  }
                  disabled={false}
                />
              </Col>
              <Col md={24} lg={12}>
                <Form.Item
                  required
                  label={`Competition Image (${locale?.label ?? ""})`}
                  extra=""
                  validateStatus={
                    errors.translations?.[locale?.key]?.imageUrl && "error"
                  }
                  help={
                    <Typography.Paragraph type="danger">
                      {errors.translations?.[locale?.key]?.imageUrl?.message}
                    </Typography.Paragraph>
                  }
                >
                  <DropAndCrop
                    title={`Competition Image (${locale?.label ?? ""})`}
                    key={`translations.${locale?.key}.imageUrl`}
                    initialUrl={
                      watch(`translations.${locale?.key}.imageUrl`) ?? ""
                    }
                    setUploadUrl={(url: string) => {
                      handleCompetitionImageUrl({
                        locale: locale.key,
                        url,
                        fieldName: "imageUrl",
                      });
                    }}
                    uploadImage={uploadCompetitionImage}
                    lockedRatio={AspectRatio.Free}
                    defaultCropBoxWidth={100}
                    defaultCropBoxHeight={100}
                    setBlurhash={(url: string) =>
                      handleCompetitionImageUrl({
                        locale: locale.key,
                        url,
                        fieldName: "blurhash",
                      })
                    }
                  />
                  <Typography.Paragraph type="danger">
                    Ideal ratio is 4:3 (1200 x 900)
                  </Typography.Paragraph>
                </Form.Item>
                <Form.Item label="Start | End" key="active">
                  <Controller
                    name="startEnd"
                    control={control}
                    defaultValue={[
                      moment(Date.now()),
                      moment(Date.now()).add(1, "week"),
                    ]}
                    render={({ field: { onChange, value } }) => (
                      <RangePicker
                        format={"YYYY-MM-DD HH:mm"}
                        defaultValue={[moment(Date.now()), moment(Date.now())]}
                        showTime
                        value={value}
                        onChange={onChange}
                        size={"large"}
                      />
                    )}
                  />
                </Form.Item>
                <BJSelectFormItemLevel
                  control={control}
                  fieldName={"level"}
                  error={!!errors?.level?.message}
                  message={errors?.level?.message}
                  required={false}
                />
                <Form.Item label="Active" key="active">
                  <Controller
                    control={control}
                    name="isActive"
                    render={({ field: { onChange, value } }) => (
                      <Switch onChange={onChange} checked={value} />
                    )}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <div className="d-flex justify-content-between align-items-center mb-2 ">
                  <p className="m-0">Questions</p>
                  <Space>
                    <BJButton
                      size="small"
                      buttonType={ButtonTypes.Save}
                      onClick={() => {
                        setShowAddQuestion(true);
                      }}
                    >
                      Add
                    </BJButton>
                  </Space>
                </div>
                <List bordered>
                  {questions?.map(Question => (
                    <StyledListGroupItem
                      onClick={() => setSelectedQuestion(Question)}
                      key={Question.id}
                    >
                      <StyledGrid>
                        {currentCountry?.locales.map((item, index) => {
                          return (
                            <>
                              <StyledQuestion>
                                {Question?.translations?.[item.key]?.question}
                              </StyledQuestion>
                              {index === 0 && <Divider type="vertical" />}
                            </>
                          );
                        })}
                      </StyledGrid>
                    </StyledListGroupItem>
                  ))}
                </List>
              </Col>
            </Row>
          </>
        )}
      </FormEdit>
    </>
  );
};

const StyledListGroupItem = styled(List.Item)`
  width: 100%;
  cursor: pointer;
`;
const StyledGrid = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
`;

const StyledQuestion = styled.div`
  width: 49%;
  cursor: "pointer";
`;
