export const convertToYears = (n: number) => {
  if (n > 24) {
    const year = n / 12;
    const description = `${year} years old`;

    return `(${description})`;
  }

  return "";
};
