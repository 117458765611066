import { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useDietaryAdvices, useCountry } from "../../context";
import { CenteredSpinner } from "../../components";
import BJList from "../../components/theme/components/BJList";
import { BJColumnType } from "../../components/theme/molecules/BJTable";
import { Sorter } from "../../components/theme/util/sorter";
import { newRoute } from "../../routes";
import { DeepLinkType, downloadCSV } from "../../utils";
import { DeepLinkForList } from "../../components/theme";

interface datasourceType extends Partial<FullDietaryAdvice> {
  categoryTitle: string | undefined;
  deepLink?: DeepLinkProps;
  ragCorpus?: boolean;
}

export const DietaryAdviceListPage = () => {
  const { dietaryAdvices, categories, loading } = useDietaryAdvices();
  const [originalTable, setOriginalTable] = useState<datasourceType[]>([]);

  const { currentCountry, primaryLocale } = useCountry();

  const defaultLocale = useMemo(() => {
    const [defaultLocale] = currentCountry?.locales.filter(
      locale => locale.primary
    );
    return defaultLocale;
  }, [currentCountry?.locales]);

  useEffect(() => {
    const dietaryAdvicesTableSource: datasourceType[] = dietaryAdvices.map(
      dietaryAdvices => ({
        id: dietaryAdvices?.id,
        key: dietaryAdvices.id,
        name: dietaryAdvices?.translations?.[defaultLocale.key]?.name,
        categoryTitle:
          dietaryAdvices.category?.translations?.[defaultLocale.key]?.title,
        ragCorpus: dietaryAdvices.ragCorpus,
        deepLink: {
          type: DeepLinkType.Dietary,
          id: dietaryAdvices?.id,
          countryCode: currentCountry?.abb,
        },
      })
    );
    setOriginalTable(dietaryAdvicesTableSource);
  }, [currentCountry?.abb, defaultLocale, dietaryAdvices]);

  const onClickRow = (record: datasourceType) => {
    return {
      onClick: () => {
        navigate(record.id);
      },
    };
  };

  const handleNewDietary = () => {
    navigate(newRoute);
  };

  const navigate = useNavigate();
  const columns: BJColumnType<datasourceType>[] = [
    {
      title: "Title",
      dataIndex: "name",
      key: "name",
      width: 2,
      ellipsis: true,
      onFilter: (value, record) => record.name.includes(value as string),
      sorter: {
        compare: Sorter.DEFAULT,
      },
    },
    {
      title: "Category",
      dataIndex: "categoryTitle",
      key: "category",
      width: 1,
      ellipsis: true,
      sorter: {
        compare: Sorter.DEFAULT,
      },
    },
    {
      title: "RAG Corpus",
      dataIndex: "ragCorpus",
      key: "ragCorpus",
      width: 1,
      render: ragCorpus => (ragCorpus ? "Yes" : "No"),
      sorter: {
        compare: Sorter.DEFAULT,
      },
    },
    DeepLinkForList(),
  ];

  if (loading) return <CenteredSpinner />;

  return (
    <BJList
      filterOnlyDisplayList
      addButtonCaption={"New Dietary Advice"}
      title={"Dietary advice"}
      OriginalList={originalTable}
      downloadData={downloadCSV({
        topic: "dietary",
        dietaryAdvices,
        categories,
        primaryLocale,
      })}
      columns={columns}
      onClickRow={onClickRow}
      onclick={handleNewDietary}
      recordCountSuffix={"Dietary advice(s)"}
      isUpload
      topic="dietary"
    />
  );
};
