import { Card } from "antd";
import { Fragment, useCallback, useMemo, useState } from "react";

import { Sorter } from "../../../components/theme/util/sorter";
import { UserService } from "../../../services";

import BJTable, {
  BJColumnType,
} from "../../../components/theme/molecules/BJTable";
import BJButton, { ButtonTypes } from "../../../components/theme/atoms/Button";
import { BJNotification, NotificationType } from "../../../components/theme";

export type BirthPlanProps = {
  docId: string;
  user: {
    uid: string;
    email: string;
    locale: string;
    country: string;
  };
  name?: string;
  partnerName?: string;
  estimatedDueDate?: string;
  chosenMaternityWard?: string;
  socialSecurityNumber?: string;
  supportPartnerName?: string;
};

const columns: BJColumnType<BirthPlanProps>[] = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    width: 2,
    ellipsis: true,
    sorter: {
      compare: Sorter.DEFAULT,
    },
  },
  {
    title: "Partner",
    dataIndex: "partnerName",
    key: "partnerName",
    width: 2,
    ellipsis: true,
    sorter: {
      compare: Sorter.DEFAULT,
    },
  },
  {
    title: "Estimated Due Date",
    dataIndex: "estimatedDueDate",
    key: "estimatedDueDate",
    width: 2,
    ellipsis: true,
    sorter: {
      compare: Sorter.DEFAULT,
    },
  },
  {
    title: "Maternity Ward",
    dataIndex: "chosenMaternityWard",
    key: "chosenMaternityWard",
    width: 2,
    ellipsis: true,
    sorter: {
      compare: Sorter.DEFAULT,
    },
  },
  {
    title: "Social Security Number",
    dataIndex: "socialSecurityNumber",
    key: "socialSecurityNumber",
    width: 2,
    ellipsis: true,
    sorter: {
      compare: Sorter.DEFAULT,
    },
  },
  {
    title: "Support Partner",
    dataIndex: "supportPartnerName",
    key: "supportPartnerName",
    width: 2,
    ellipsis: true,
    sorter: {
      compare: Sorter.DEFAULT,
    },
  },
];

export const BirthPlansInformation = ({
  birthplans,
  selfEmail,
}: {
  birthplans: BirthPlanProps[];
  selfEmail: string;
}) => {
  const [indexSendingPDF, toggleIndexSendingPDF] = useState(-1);
  const [indexSendingSelfPDF, toggleIndexSendingSelfPDF] = useState(-1);

  const onClickResendAttachment = useCallback(
    async (
      birthplan: BirthPlanProps,
      index: number,
      toggleIndex: (index: number) => void
    ) => {
      try {
        toggleIndex(index);

        await UserService.onSendAttachments({
          ...birthplan.user,
          docId: birthplan.docId,
        });

        BJNotification({
          type: NotificationType.Success,
          message: "Success",
          description: "Birthplan has been sent",
        });
      } catch (error) {
        BJNotification({
          type: NotificationType.Error,
          message: "Failed",
          description: (error as Error).message,
        });
      } finally {
        toggleIndex(-1);
      }
    },
    []
  );

  const renderBirthPlanButton = useCallback(
    ({
      index,
      onClick,
      buttonType,
      title,
      indexSending,
    }: {
      index: number;
      onClick: () => void;
      buttonType: ButtonTypes;
      title: string;
      indexSending: number;
    }) => {
      return (
        <BJButton
          loading={indexSending === index}
          size="middle"
          buttonType={buttonType}
          onClick={onClick}
          block
          style={{ whiteSpace: "normal", height: "auto", fontSize: 12 }}
        >
          {title}
        </BJButton>
      );
    },
    []
  );

  const columnsWithRender = useMemo(() => {
    return [
      ...columns,
      {
        title: "Action",
        dataIndex: "docId",
        key: "docId",
        width: 2,
        ellipsis: true,
        render(_: unknown, record: BirthPlanProps, index: number) {
          const onClickAttachment = () => {
            onClickResendAttachment(record, index, toggleIndexSendingPDF);
          };
          return renderBirthPlanButton({
            index,
            onClick: onClickAttachment,
            buttonType: ButtonTypes.Primary,
            title: "Resend attachment",
            indexSending: indexSendingPDF,
          });
        },
      },
      {
        title: "Self Action",
        dataIndex: "selfId",
        key: "selfId",
        width: 2,
        ellipsis: true,
        render(_: unknown, record: BirthPlanProps, index: number) {
          const onClickAttachment = () => {
            onClickResendAttachment(
              {
                ...record,
                user: {
                  ...record.user,
                  email: selfEmail, //send to BO user
                },
              },
              index,
              toggleIndexSendingSelfPDF
            );
          };

          return renderBirthPlanButton({
            index,
            onClick: onClickAttachment,
            buttonType: ButtonTypes.Secondary,
            title: "Send to Myself",
            indexSending: indexSendingSelfPDF,
          });
        },
      },
    ];
  }, [
    indexSendingPDF,
    indexSendingSelfPDF,
    onClickResendAttachment,
    renderBirthPlanButton,
    selfEmail,
  ]);

  return (
    <Card title="Birth Plans Information" bordered={false}>
      {birthplans.length ? (
        <Fragment>
          <BJTable
            hideRecordCount
            pagination={false}
            columns={columnsWithRender}
            dataSource={birthplans}
          />
        </Fragment>
      ) : (
        <Fragment />
      )}
    </Card>
  );
};
