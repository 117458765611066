import { FC } from "react";

import {
  ArticlesProvider,
  BirthStoriesProvider,
  BlogCategoriesProvider,
  BlogPostsProvider,
  ChildBlogPostsProvider,
  InfluencersProvider,
  OffersProvider,
  SponsorsProvider,
  VerifiersProvider,
  DietaryAdvicesProvider,
  FAQProvider,
  MonthlyInformationProvider,
  MilestonesProvider,
  ChecklistsProvider,
  PopularProvider,
  RecommendedProvider,
  PodcastsProvider,
  WeeklyInformationProvider,
  ContentsProvider,
  GeneralBlogPostsProvider,
  HighlightsProvider,
  TipsProvider,
  TagsProvider,
  BannersProvider,
  ImagesProvider,
  CompetitionsProvider,
  ContentCollectionProvider,
  ApplicationCommonContextProvider,
  AppTakeOverProvider,
  PollsProvider,
  MiniJourneysProvider,
  MissionsProvider,
  AppLinksProvider,
  SymptomsProvider,
} from "./context";
import { ContentMenuProvider } from "./context/ContentMenuContext";
import { RemoteNotificationProvider } from "./context/RemoteNotificationContext";
import { CountryProvider } from "./context";
import { ToolsProvider } from "./context/ToolsContext";

interface Props {
  children: any;
}

export const Providers = ({ children }: Props) => (
  <Compose
    components={[
      CountryProvider,
      BlogCategoriesProvider,
      BlogPostsProvider,
      ChildBlogPostsProvider,
      BirthStoriesProvider,
      InfluencersProvider,
      ToolsProvider,
      OffersProvider,
      SponsorsProvider,
      VerifiersProvider,
      DietaryAdvicesProvider,
      FAQProvider,
      MonthlyInformationProvider,
      MilestonesProvider,
      ChecklistsProvider,
      WeeklyInformationProvider,
      ContentsProvider,
      PopularProvider,
      RecommendedProvider,
      PodcastsProvider,
      ArticlesProvider,
      GeneralBlogPostsProvider,
      HighlightsProvider,
      TipsProvider,
      TagsProvider,
      RemoteNotificationProvider,
      BannersProvider,
      ImagesProvider,
      CompetitionsProvider,
      ContentCollectionProvider,
      ApplicationCommonContextProvider,
      ContentMenuProvider,
      AppTakeOverProvider,
      PollsProvider,
      MiniJourneysProvider,
      MissionsProvider,
      AppLinksProvider,
      SymptomsProvider,
    ]}
  >
    {children}
  </Compose>
);

interface ComposeProps {
  components: any[];
}

export const Compose: FC<ComposeProps> = ({ components, children }) => (
  <>
    {[...components].reverse().reduce((acc, curr) => {
      const [Provider, props] = Array.isArray(curr)
        ? [curr[0], curr[1]]
        : [curr, {}];
      return <Provider {...props}>{acc}</Provider>;
    }, children)}
  </>
);
