import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Filter } from "./Filter";
import { usePostList } from "../../hooks";
import {
  BlogTypes,
  convertToYears,
  createURLParams,
  getURLParams,
} from "../../utils";
import { useCountry, useInfluencers } from "../../context";
import BJSelect from "../../components/theme/atoms/BJSelect";
import BJList from "../../components/theme/components/BJList";
import { BJColumnType } from "../../components/theme/molecules/BJTable";
import { Sorter } from "../../components/theme/util/sorter";
import { getMonths, getWeeks } from "../../utils/timeUtils";
import {
  GetBlogPostBirthRoute,
  GetBlogPostChildRoute,
  GetBlogPostPregnancyRoute,
  GetGeneralBlogPostRoute,
} from "../../routes";
import { DeepLinkForList } from "../../components/theme";
import { useTranslation } from "react-i18next";

type datasourceType = { deepLink?: DeepLinkProps } & Post;

export const PostListPage = () => {
  const navigate = useNavigate();
  const { posts } = usePostList();
  const { t } = useTranslation();
  const title = t("blogpost:title");

  const postTypes: PostTypeFilterItem[] = [
    {
      text: `${title} (Pregnancy)`,
      value: BlogTypes.BlogPost,
      checked: false,
    },
    {
      text: `${title} (Child)`,
      value: BlogTypes.ChildBlogPost,
      checked: false,
    },
    { text: "Birth stories", value: BlogTypes.BirthStory, checked: false },
    {
      text: `${title} (General)`,
      value: BlogTypes.GeneralBlogPost,
      checked: false,
    },
  ];

  const { weeks } = getWeeks();
  const { months } = getMonths({
    showHalfYearly: true,
  });
  const { influencers, loading: loadingInfluencers } = useInfluencers();
  const { currentCountry, primaryLocale } = useCountry();

  const [searchQuery, setSearchQuery] = useState("");

  const [filterInitialized, setFilterInitialized] = useState(false);

  const [originalTable, setOriginalTable] = useState<datasourceType[]>([]);

  const [filters, setFilters] = useState<IListFilters>({
    weeks: {
      data: weeks.map(w => ({
        text: `Vecka ${w.number}`,
        value: w.number.toString(),
      })),
      selected: new Set(),
    },
    months: {
      data: months.map(m => ({
        text: `Månad ${m.number} ${convertToYears(m.number)}`,
        value: m.number.toString(),
      })),
      selected: new Set(),
    },
    influencers: { data: [], selected: new Set() },
    types: { data: postTypes, selected: new Set() },
  });

  useEffect(() => {
    const postTableSource: datasourceType[] = posts.map(post => ({
      ...post,
      deepLink: {
        type: post.type,
        id: post?.id,
        countryCode: currentCountry?.abb,
      },
      title: post?.translations?.[primaryLocale?.key]?.title ?? post?.title,
    }));
    setOriginalTable(postTableSource);
  }, [currentCountry?.abb, posts, primaryLocale?.key]);

  const updateURLFilter = (filterName: string, selected: Set<string>) => {
    const selectedString = Array.from(selected).join(",");

    const searchParams = getURLParams();
    if (selectedString) {
      searchParams[filterName] = selectedString;
    } else {
      delete searchParams[filterName];
    }

    navigate({ search: createURLParams(searchParams) });
  };

  const onChangeFilter = (filterName: keyof IListFilters, value: string) => {
    if (!filters[filterName]) {
      return;
    }
    const _filter = { ...filters[filterName] };

    const selected = new Set(_filter.selected);
    if (selected.has(value)) {
      selected.delete(value);
    } else {
      selected.add(value);
    }
    _filter.selected = selected;

    updateURLFilter(filterName, selected);

    setFilters({ ...filters, [filterName]: _filter });
  };

  const onCheckAll = (filterName: keyof IListFilters) => {
    if (!filters[filterName]) {
      return;
    }
    const _filter = { ...filters[filterName] };

    const allSelected = _filter.data.every(d => _filter.selected.has(d.value));

    if (allSelected) {
      _filter.selected = new Set();
    } else {
      _filter.selected = new Set(_filter.data.map(d => d.value));
    }

    updateURLFilter(filterName, _filter.selected);

    setFilters({ ...filters, [filterName]: _filter });
  };

  useEffect(() => {
    if (filterInitialized) {
      return;
    }
    setFilterInitialized(true);

    const params = getURLParams();
    if (!params) {
      return;
    }

    let newFilters: any = { ...filters };

    for (const key in filters) {
      const param = params[key];
      if (!param) {
        continue;
      }

      newFilters = {
        ...newFilters,
        [key]: {
          data: newFilters[key].data,
          selected: new Set(param.split(",")),
        },
      };
    }

    setFilters(newFilters);
  }, [filters, filterInitialized, loadingInfluencers]);

  useEffect(() => {
    setFilters(prev => ({
      ...prev,
      influencers: {
        ...prev.influencers,
        data: influencers.map(i => ({ text: i.name, value: i.id })),
      },
    }));
  }, [influencers]);

  const filteredPosts = useMemo((): Post[] => {
    const filterOnWeek = (post: Post) => {
      if (filters.weeks.selected.size > 0) {
        return post.week === null
          ? false
          : filters.weeks.selected.has(post?.week?.toString());
      }
      return true;
    };

    const filterOnMonth = (post: Post) => {
      if (filters.months.selected.size > 0) {
        return post.month === null
          ? false
          : filters.months.selected.has(post?.month?.toString());
      }
      return true;
    };

    const filterOnInfluencer = (post: Post) => {
      if (filters.influencers.selected.size > 0) {
        return filters.influencers.selected.has(post.influencerId);
      }
      return true;
    };

    const filterOnType = (post: Post) => {
      if (filters.types.selected.size > 0) {
        return filters.types.selected.has(post?.type?.toString());
      }
      return true;
    };

    const filterOnSearchQuery = (post: Post) => {
      return post?.translations
        ? post?.translations?.[primaryLocale.key]?.title
            ?.toLowerCase()
            ?.includes(searchQuery.toLowerCase())
        : post.title?.toLowerCase().includes(searchQuery.toLowerCase());
    };

    return originalTable
      .filter(filterOnWeek)
      .filter(filterOnMonth)
      .filter(filterOnInfluencer)
      .filter(filterOnType)
      .filter(filterOnSearchQuery);
  }, [filters, originalTable, primaryLocale?.key, searchQuery]);

  const resetFilters = useCallback(() => {
    setFilters({
      ...filters,
      weeks: { ...filters.weeks, selected: new Set() },
      months: { ...filters.months, selected: new Set() },
      influencers: { ...filters.influencers, selected: new Set() },
      types: { ...filters.types, selected: new Set() },
    });
    navigate({ search: undefined });
    setSearchQuery("");
  }, [filters, navigate]);

  const onChangeHandlerNewPost = (value: string) => {
    switch (value) {
      case BlogTypes.BlogPost: {
        navigate(GetBlogPostPregnancyRoute());
        break;
      }
      case BlogTypes.ChildBlogPost: {
        navigate(GetBlogPostChildRoute());
        break;
      }
      case BlogTypes.BirthStory: {
        navigate(GetBlogPostBirthRoute());
        break;
      }
      case BlogTypes.GeneralBlogPost: {
        navigate(GetGeneralBlogPostRoute());
        break;
      }
      default: {
        break;
      }
    }
  };

  const onClickRow = (record: Post) => {
    return {
      onClick: () => navigate(record.id),
    };
  };

  const columns: BJColumnType<
    Post & { influencerName?: string; month: number | string }
  >[] = [
    {
      title: "Name",
      dataIndex: "title",
      width: 2,
      key: "title",
      sorter: {
        compare: Sorter.DEFAULT,
      },
    },
    {
      title: "Influencer",
      dataIndex: ["influencerName"],
      key: "influencerName",
      width: 1,
      sorter: {
        compare: Sorter.DEFAULT,
      },
    },
    {
      title: "Month",
      dataIndex: "month",
      key: "month",
      width: 1,
      sorter: {
        compare: Sorter.DEFAULT,
      },
    },
    {
      title: "Week",
      dataIndex: "week",
      key: "week",
      width: 1,
      sorter: {
        compare: Sorter.DEFAULT,
      },
    },
    DeepLinkForList(),
  ];

  const downloadData = () => {
    const csvData = [["Id", "Title", "Description", "Influencer"]];
    originalTable.forEach(t => {
      csvData.push([
        t.id.toString(),
        t.title,
        t?.description?.replace(/"/g, '""') || "",
        t?.influencer?.name?.replace(/"/g, '""') || "",
      ]);
    });
    return csvData;
  };

  return (
    <BJList
      title={`${title}`}
      OriginalList={filteredPosts.map(post => ({
        ...post,
        key: post.id,
        influencerName: post.influencer
          ? post.influencer.name
          : "Not available",
      }))}
      downloadData={downloadData()}
      columns={columns}
      onClickRow={onClickRow}
      recordCountSuffix={`${title}`}
      filters={
        <Filter
          filters={filters}
          onChangeFilter={onChangeFilter}
          onCheckAll={onCheckAll}
          resetFilter={resetFilters}
        />
      }
      prefix={
        <BJSelect
          options={[
            { label: `${title} (pregnancy)`, value: BlogTypes.BlogPost },
            { label: `${title} (child)`, value: BlogTypes.ChildBlogPost },
            { label: `${title} Birth story`, value: BlogTypes.BirthStory },
            {
              label: `${title} (General)`,
              value: BlogTypes.GeneralBlogPost,
            },
          ]}
          placeholder="New Post"
          onChange={onChangeHandlerNewPost}
          size="large"
        />
      }
    />
  );
};
