import { httpsCallable } from "firebase/functions";
import firebase, { firestore, functions } from "../firebase";
import { cloudFunctionNames, firebaseCollectionNames } from "../utils/consts";
import { logException } from "../utils/exceptionLogger";
import {
  collection,
  getDocs,
  onSnapshot,
  QuerySnapshot,
} from "firebase/firestore";
import { DoulaToolsData } from "../pages/Doula/DailyDoulaToolsPage";

interface EntertainMeContent {
  content: {
    list: { id: string; type: "article" | "videoArticle" | "tool" }[];
  };
}

const toRAGCollection = (
  doc: firebase.firestore.QueryDocumentSnapshot
): RAGCollection => {
  return {
    collectionName: doc.id,
    ...(doc.data() as RAGCollection),
  };
};

const createSubscription = (
  path: string,
  callback: (snapshot: QuerySnapshot<firebase.firestore.DocumentData>) => void
) => {
  return onSnapshot(firestore.collection(path), callback);
};

const syncDiff = async (
  countryCode: string,
  language: string,
  contentType: string
) => {
  const callable = httpsCallable(functions, `embedAll${contentType}`);
  try {
    await callable({
      countryCode,
      language,
      syncMethod: "diff",
    });
    return true;
  } catch (err) {
    logException(err);

    return false;
  }
};

const syncAllAndCleanup = async (
  countryCode: string,
  language: string,
  contentType: string
) => {
  const callable = httpsCallable(functions, `embedAll${contentType}`);

  try {
    await callable({
      countryCode,
      language,
      syncMethod: "all",
    });
    return true;
  } catch (err) {
    logException(err);
    return false;
  }
};

const getTotalCount = async (
  countryCode: string,
  language: string,
  contentType: string
) => {
  try {
    const collectionRef = collection(
      firestore,
      contentType !== "checklists"
        ? `${contentType}/${countryCode}/${contentType}Content`
        : `${contentType}/${countryCode}/content`
    );
    let count = 0;
    const docs = await getDocs(collectionRef);
    docs.forEach(element => {
      if (element.data()["translations"][language]) count++;
    });
    return count;
  } catch (err) {
    logException(err);
    return undefined;
  }
};

const getEmbededCount = async (countryCode: string, language: string) => {
  try {
    const data = await firestore
      .collection(`ragCorpus/${countryCode}_${language}/searchData`)
      .get();

    const count: Record<string, number> = {};
    const snapshot = data.docs;
    snapshot.forEach(doc => {
      const data = doc.data();
      if (count[data["type"]] === undefined) {
        count[data["type"]] = 0;
      }
      count[data["type"]]++;
    });
    return count;
  } catch (err) {
    logException(err);
    return {};
  }
};

const embedArticles = async (
  countryCode: string,
  language: string,
  content: string
) => {
  const callable = httpsCallable(
    functions,
    cloudFunctionNames.doula.contentEmbedding
  );

  try {
    await callable({
      country: countryCode,
      language: language,
      content: content,
    });
    return true;
  } catch (err) {
    return false;
  }
};

const subscribe = (
  callback: (err: Error | null, ragCollections: RAGCollection[]) => void
) =>
  firebase
    .firestore()
    .collection(firebaseCollectionNames.ragCorpus)
    .onSnapshot(
      snapshot => {
        const ragCollections = snapshot.docs.map(toRAGCollection);
        callback(null, ragCollections);
      },
      err => {
        logException(err);
        callback(err, []);
      }
    );

const subscribeToLogs = (
  countryCode: string,
  language: string,
  callback: (err: Error | null, logs: string[]) => void
) =>
  firebase
    .firestore()
    .collection(firebaseCollectionNames.ragCorpus)
    .doc(`${countryCode}_${language}`)
    .onSnapshot(
      snapshot => {
        const logs: string[] = snapshot.data()?.logs || [];
        callback(null, logs.reverse());
      },
      err => {
        logException(err);
        callback(err, []);
      }
    );

const getStatus = async (): Promise<RAGCollection[]> =>
  firebase
    .firestore()
    .collection("ragCorpus")
    .get()
    .then((snapshot): RAGCollection[] =>
      snapshot.docs.map(doc => toRAGCollection(doc))
    )
    .catch((error: Error): RAGCollection[] => {
      logException(error);
      return [];
    });

const articleEmbedding = async (
  countryCode: string,
  language: string,
  syncMethod: string
) => {
  const callable = httpsCallable(
    functions,
    cloudFunctionNames.doula.articleEmbedding
  );

  try {
    await callable({
      country: countryCode,
      language: language,
      syncMethod: syncMethod,
    });
    return true;
  } catch (err) {
    return false;
  }
};

const addEntertainMeContent = async (
  data: EntertainMeContent,
  countryCode: Country["abb"]
) => {
  await firebase
    .firestore()
    .collection(`doulaOperations/${countryCode}/content`)
    .doc("entertainme")
    .set(data);
};

const getEntertainMeContent = async (
  countryCode: Country["abb"]
): Promise<EntertainMeContent["content"]> => {
  const doc = await firebase
    .firestore()
    .collection(`doulaOperations/${countryCode}/content`)
    .doc("entertainme")
    .get();
  return doc.data()?.content || { list: [] };
};

const getDoulaToolsData = async (): Promise<DoulaToolsData> => {
  try {
    const getDoulaTools = httpsCallable(
      functions,
      cloudFunctionNames.doula.getTools
    );
    const result = await getDoulaTools();
    return result.data as DoulaToolsData;
  } catch (error) {
    logException(error);
    throw error;
  }
};

const updateDoulaToolsData = async (
  data: DoulaToolsData | null
): Promise<void> => {
  try {
    if (!data) return;
    const updateDoulaTools = httpsCallable(
      functions,
      cloudFunctionNames.doula.updateTools
    );
    await updateDoulaTools({ ...data });
  } catch (error) {
    logException(error);
    throw error;
  }
};

export const DoulaServices = {
  getTotalCount,
  getEmbededCount,
  syncDiff,
  createSubscription,
  syncAllAndCleanup,
  embedArticles,
  subscribe,
  subscribeToLogs,
  getStatus,
  articleEmbedding,
  addEntertainMeContent,
  getEntertainMeContent,
  getDoulaToolsData,
  updateDoulaToolsData,
};
