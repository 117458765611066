import firebase from "../firebase";
import { CollectionPaths } from "../helper/collections";
import { logException } from "../utils/exceptionLogger";
import { CountryCodeService } from "./CountryCodeService";

export class DietaryAdviceService extends CountryCodeService {
  constructor(countryCode: string) {
    super(countryCode);
  }

  static toDietaryAdvice = (
    doc: firebase.firestore.DocumentData
  ): DietaryAdviceV2 => {
    const data = doc.data();

    return {
      id: doc.id,
      ...data,
      tagWords: data.tagWords ?? [],
      translations: data.translations ?? {},
      ragCorpus: data.ragCorpus ?? true,
    };
  };

  subscribe = (
    callback: (err: Error | null, dietaryAdvices: DietaryAdviceV2[]) => void
  ) =>
    firebase
      .firestore()
      .collection(CollectionPaths.DietaryAdvices[this.countryCode])
      .onSnapshot(
        snapshot => {
          const dietaryAdvices = snapshot.docs.map(
            DietaryAdviceService.toDietaryAdvice
          );
          callback(null, dietaryAdvices);
        },
        err => {
          logException(err);
          callback(err, []);
        }
      );

  create = (data: Omit<DietaryAdviceV2, "id">) =>
    firebase
      .firestore()
      .collection(CollectionPaths.DietaryAdvices[this.countryCode])
      .add(data);

  update = (id: DietaryAdvice["id"], data: Omit<DietaryAdviceV2, "id">) =>
    firebase
      .firestore()
      .collection(CollectionPaths.DietaryAdvices[this.countryCode])
      .doc(id)
      .update({ ...data });

  delete = (id: string) =>
    firebase
      .firestore()
      .collection(CollectionPaths.DietaryAdvices[this.countryCode])
      .doc(id)
      .delete();
}
