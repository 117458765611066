import { FormModal } from "../../components/FormModal";
import { useForm } from "react-hook-form";
import { commonErrors } from "../../language";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { formValidationError } from "../../utils";
import { useCountry } from "../../context";
import { CountryService } from "../../services/CountryService";
import { BJInputFormItem } from "../../components/theme";
import { useEffect } from "react";

interface Props {
  symptom: Symptom | null;
  show: boolean;
  onHide: () => void;
}

export type SymptomType = { [locale: string]: { [key: string]: string } };

type FormValues = {
  key: string;
  translations: {
    [locale: string]: {
      title: string;
    };
  };
};

const { requiredError2: requiredError } = commonErrors;

const getSchema = (currentCountry: Country) =>
  yup.object().shape({
    key: yup.string().required(`Key: ${requiredError}`),
    translations: yup.object().shape(
      currentCountry?.locales.reduce((acc, item) => {
        acc[item.key] = yup.object().shape({
          title: yup
            .string()
            .required(
              `Title (${String(item.key).toUpperCase()}): ${requiredError}`
            ),
        });
        return acc;
      }, {} as any)
    ),
  });

export const SymptomModal = ({ symptom, show, onHide }: Props) => {
  const { currentCountry } = useCountry();

  const {
    formState,
    handleSubmit,
    reset,
    control,
    watch,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(getSchema(currentCountry)),
  });

  useEffect(() => {
    reset({
      key: symptom?.key ?? "",
      translations: symptom?.translations ?? {},
    });
  }, [symptom, reset, show]);

  const onDelete = async () => {
    if (symptom !== null) {
      await CountryService.deleteSymptom({
        locale: currentCountry?.abb,
        key: symptom?.key,
      });
    } else {
      throw new Error("Symptom not found");
    }
  };

  const onSubmit = async ({ translations, key }: FormValues) => {
    await CountryService.updateSymptom({
      locale: currentCountry?.abb,
      translations,
      key,
    });
  };

  const isDirty = !!Object.keys(formState.dirtyFields).length;
  const name = watch(`translations.${currentCountry?.abb}.title`);

  return (
    <FormModal
      onHide={onHide}
      enableSave={isDirty}
      show={show}
      onSubmit={handleSubmit(onSubmit, formValidationError)}
      error={null}
      onDelete={onDelete}
      enableDelete={!!symptom}
      modalSubTitle={symptom ? `Id - ${symptom?.key}` : ""}
      localeSupported
      modalTitle={symptom ? name : "Add new symptom"}
      recordIdentifier={symptom?.translations?.[currentCountry?.abb]?.title}
    >
      {locale => (
        <>
          <BJInputFormItem
            label={`Key`}
            fieldName={`key`}
            key={`key`}
            disabled={!!symptom}
            control={control}
            error={!!errors?.key}
            message={errors?.key?.message}
            required={true}
          />
          <BJInputFormItem
            label={`Name (${locale?.label ?? ""})`}
            fieldName={`translations.${locale?.key}.title`}
            key={`translations.${locale?.key}.title`}
            control={control}
            error={!!errors?.translations?.[locale?.key]?.title}
            message={errors?.translations?.[locale?.key]?.title?.message}
            required={true}
          />
        </>
      )}
    </FormModal>
  );
};
