import { useFormContext } from "react-hook-form";
import { BJSelectFormItem } from "../molecules/formItems/BJFormSelectItem";
import { useEffect, useMemo, useState } from "react";
import { SearchService } from "../../../services/SearchService";
import { useCountry } from "../../../context";
import * as yup from "yup";

export interface CityFormProps {
  cities: string[];
}

export const BJCitySegmentSchema = (required?: boolean) => {
  return {
    cities: required
      ? yup.array().of(yup.string()).required("Please select a city/cities")
      : yup.array().of(yup.string()).nullable(),
  };
};

/**
 * The BJCustomSegment component is a reusable React component used for selecting and displaying data in a form.
 * @function
 * @return {JSX.Element} A JSX element representing the BJCustomSegment component.
 */

type BJCitySegmentProps = {
  label: string;
  required?: boolean;
  isSelectAll?: boolean;
  multiple?: boolean;
  size?: "small" | "middle" | "large";
};

export const BJCitySegment = ({
  label,
  isSelectAll = false,
  required = false,
  multiple = false,
  size = "small",
}: BJCitySegmentProps) => {
  const [cities, setCities] = useState<string[]>([]);
  const { currentCountry } = useCountry();
  const {
    setValue,
    control,
    watch,
    formState: { errors },
  } = useFormContext<CityFormProps>();

  const { cities: selectedCities } = watch();
  const searchService = useMemo(
    () => new SearchService(currentCountry?.abb),
    [currentCountry?.abb]
  );

  useEffect(() => {
    if (!searchService) {
      return;
    }

    searchService
      .getData()
      .then(data => {
        setCities(data.municipalities.names);
      })
      .catch(error => {
        console.log(error);
      });
  }, [searchService]);

  return (
    <BJSelectFormItem
      size={size}
      extra="You may select one or more cities"
      multiple={multiple}
      isSelectAll={isSelectAll}
      required={required}
      fieldName={"cities"}
      control={control}
      message={errors?.cities?.map((error: any) => error.message)[0]}
      error={!!errors?.cities}
      label={label}
      optionsList={cities.map(city => ({
        key: city,
        value: city,
        display: city,
      }))}
      isClearable={selectedCities?.length > 0}
      onClear={() => setValue("cities", [], { shouldDirty: true })}
      handleChange={value => {
        if (value.includes("all")) {
          const isSelectAll =
            value?.length === 1 || value?.length !== cities?.length + 1;
          setValue("cities", isSelectAll ? cities : []);
        }
      }}
    />
  );
};
