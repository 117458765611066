import { Navigate, Route, Routes } from "react-router-dom";
import { CMSLayout } from "../../components";
import {
  ArticlePage,
  BannerPage,
  BannersListPage,
  BlogCategoryListPage,
  ChecklistListPage,
  ChecklistPage,
  ContentList,
  DietaryAdvicePage,
  EditContent,
  AddNewContent,
  FAQListPage,
  HighlightListPage,
  InfluencerListPage,
  MilestoneListPage,
  MilestonePage,
  MonthInformationPage,
  MonthlyInformationListPage,
  OfferListPage,
  OfferPage,
  PodcastListPage,
  PodcastPage,
  PollPage,
  PollsList,
  PopularPage,
  RecommendedPage,
  PostListPage,
  PostPage,
  QuestionPage,
  ToolsBannerListPage,
  ToolsMenuSectionsPage,
  WeekInformationPage,
  WeeklyInformationListPage,
  MissionsListPage,
  ChangeMissionsList,
  PopularListForChild,
  PopularListForPregnancy,
  RecommendedByMonthsListPage,
  RecommendedByWeeksListPage,
} from "../../pages";
import { AppTakeOverListPage } from "../../pages/AppTakeOver";
import { AppTakeOverPage } from "../../pages/AppTakeOver/AppTakeOverPage";
import { ArticleListPage } from "../../pages/ArticleList/ArticleListPage";
import { ArticleCategoryList } from "../../pages/ArticleList/Categories";
import { ChangeOrderList } from "../../pages/ArticleList/ChangeOrderList";
import { SponsorList } from "../../pages/ArticleList/Sponsors";
import { VerifierList } from "../../pages/ArticleList/Verifiers";
import { ChangeBannerList } from "../../pages/Banners/BannerOrderList";
import {
  CompetitionPage,
  CompetitionsListPage,
} from "../../pages/Competitions";
import { ChangeCompetitionList } from "../../pages/Competitions/ChangeCompetitionList";
import {
  ContentCollectionList,
  ContentCollectionPage,
} from "../../pages/ContentCollections";
import { ContentMenuPage } from "../../pages/ContentCollections/ContentMenu/ContentMenuPage";
import { DietaryCategoryList } from "../../pages/DietaryAdviceList/Categories";
import { DietaryAdviceContentPage } from "../../pages/DietaryAdviceList/Content";
import { DietaryAdviceListPage } from "../../pages/DietaryAdviceList/DietaryAdviceListPage";
import { MiniJourneyListPage, MiniJourneyPage } from "../../pages/MiniJourney";
import { ChangeOfferList } from "../../pages/OfferList/ChangeOfferList";
import { FaqCategoryList } from "../../pages/Question/Categories";
import { TagsList } from "../../pages/Tags/TagsList";
import { TipsList } from "../../pages/Tips/TipsList";
import { UploadImageListPage, UploadImagePage } from "../../pages/UploadImage";
import { ChangeUploadImageList } from "../../pages/UploadImage/ChangeUploadImageList";
import {
  addCompetition,
  appTakeOvers,
  banners,
  contentCollections,
  contentMenu,
  dailyDoula,
  dailyDoulaChild,
  dailyDoulaPregnancy,
  images,
  milestones,
  milestonesChild,
  milestonesPregnancy,
  miniJourneyRoute,
  polls,
  toolsMenu,
  toolsMenuBanners,
  toolsMenuSections,
  missions,
  popularChild,
  popularPregnancy,
  applinks,
  checkListCustomOffers,
  changeorder,
  loyalty,
  levels,
  dailyDoulaEntertainment,
  dailyDoulaTools,
} from "../../routes";
import { MilestonesType, TipsType } from "../commonEnums";
import { AppLinksList } from "../../pages/AppLinks/AppLinkList";
import { AppLinkPage } from "../../pages/AppLinks/AppLinkPage";
import { CustomChecklistOffersPage } from "../../pages/CustomChecklistOffers";
import { ChangeChecklistOrderList } from "../../pages/Checklist/ChangeChecklistOrderList";
import { LoyaltyLevelsInfoPage } from "../../pages/Loyalty";
import { LoyaltyProvider } from "../../context";
import { CountryGuard } from "../../components/CountryGuard";
import { OfferTopFive } from "../../pages/OfferPage/OfferTopFive";
import { EntertaineMePage } from "../../pages/Doula";
import { DailyDoulaToolsPage } from "../../pages/Doula/DailyDoulaToolsPage";

export const CMSRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<CMSLayout />}>
        <Route path={""} element={<Navigate to="articles/articles" />} />
        <Route path="articles">
          <Route path={"articles"}>
            <Route path={""} element={<ArticleListPage />} />
            <Route path={"new"} element={<ArticlePage />} />
            <Route path={":id"} element={<ArticlePage />} />
            <Route path={"changeorder"} element={<ChangeOrderList />} />
          </Route>
          <Route
            path={"article-categories"}
            element={<ArticleCategoryList />}
          />
          <Route path={"article-verifiers"} element={<VerifierList />} />
        </Route>
        <Route path={"blog-posts"}>
          <Route path={""} element={<PostListPage />} />
          <Route path={"new"} element={<PostPage />} />
          <Route path={":id"} element={<PostPage />} />
        </Route>
        <Route path={"blog-categories"} element={<BlogCategoryListPage />} />
        <Route path={"influencers"} element={<InfluencerListPage />} />
        <Route path={"offers"}>
          <Route path={""} element={<OfferListPage />} />
          <Route path={"new"} element={<OfferPage />} />
          <Route path={":id"} element={<OfferPage />} />
          <Route path={"changeorder"} element={<ChangeOfferList />} />
          <Route path={"topFiveOffer"} element={<OfferTopFive />} />
        </Route>
        <Route path={"dietary"}>
          <Route path={"dietary"}>
            <Route path={""} element={<DietaryAdviceListPage />} />
            <Route path={"new"} element={<DietaryAdvicePage />} />
            <Route path={":id"} element={<DietaryAdvicePage />} />
          </Route>
          <Route path={"dietarycategories"} element={<DietaryCategoryList />} />
          <Route
            path={"dietarycontent"}
            element={<DietaryAdviceContentPage />}
          />
        </Route>
        <Route path={"popular"}>
          <Route path={popularChild}>
            <Route path={""} element={<PopularListForChild />} />
            <Route path={":id"} element={<PopularPage />} />
          </Route>
          <Route path={popularPregnancy}>
            <Route path={""} element={<PopularListForPregnancy />} />
            <Route path={":id"} element={<PopularPage />} />
          </Route>
          <Route path={"new"} element={<PopularPage />} />
        </Route>
        <Route path={"recommended"}>
          <Route path={"months"}>
            <Route path={""} element={<RecommendedByMonthsListPage />} />
            <Route path={":id"} element={<RecommendedPage />} />
          </Route>
          <Route path={"weeks"}>
            <Route path={""} element={<RecommendedByWeeksListPage />} />
            <Route path={":id"} element={<RecommendedPage />} />
          </Route>
          <Route path={"new"} element={<RecommendedPage />} />
        </Route>
        <Route path={"monthly-information"}>
          <Route path={""} element={<MonthlyInformationListPage />} />
          <Route path={"types/:type"} element={<MonthInformationPage />} />
          <Route path={":id"} element={<MonthInformationPage />} />
        </Route>
        <Route path={"weekly"}>
          <Route path={""} element={<WeeklyInformationListPage />} />
          <Route path={":id"} element={<WeekInformationPage />} />
        </Route>

        <Route path="faq">
          <Route path={"faq"}>
            <Route path={""} element={<FAQListPage />} />
            <Route path={"new"} element={<QuestionPage />} />
            <Route path={":id"} element={<QuestionPage />} />
          </Route>
          <Route path={"faq-categories"}>
            <Route path={""} element={<FaqCategoryList />} />
          </Route>
        </Route>
        <Route path={"podcasts"}>
          <Route path={""} element={<PodcastListPage />} />
          <Route path={"new"} element={<PodcastPage />} />
          <Route path={":id"} element={<PodcastPage />} />
        </Route>
        <Route path={"checklists"}>
          <Route path={"checklists"}>
            <Route path={""} element={<ChecklistListPage />} />
            <Route path={":id"} element={<ChecklistPage />} />
            <Route path={"new"} element={<ChecklistPage />} />
            <Route path={changeorder} element={<ChangeChecklistOrderList />} />
          </Route>
          <Route
            path={checkListCustomOffers}
            element={<CustomChecklistOffersPage />}
          />
        </Route>
        <Route path={milestones}>
          <Route path={milestonesPregnancy}>
            <Route
              path={""}
              element={<MilestoneListPage type={MilestonesType.Pregnancy} />}
            />
            <Route path={"new"} element={<MilestonePage />} />
            <Route path={":id"} element={<MilestonePage />} />
          </Route>
          <Route path={milestonesChild}>
            <Route
              path={""}
              element={<MilestoneListPage type={MilestonesType.Child} />}
            />
            <Route path={"new"} element={<MilestonePage />} />
            <Route path={":id"} element={<MilestonePage />} />
          </Route>
        </Route>
        <Route path={"content"}>
          <Route path={""} element={<ContentList />} />
          <Route path={"new"} element={<AddNewContent />} />
          <Route path={":id"} element={<EditContent />} />
        </Route>
        <Route path={"highlight"} element={<HighlightListPage />} />
        <Route path={dailyDoula}>
          <Route
            path={dailyDoulaPregnancy}
            element={<TipsList type={TipsType.Pregnancy} />}
          />
          <Route
            path={dailyDoulaChild}
            element={<TipsList type={TipsType.Child} />}
          />
          <Route
            path={dailyDoulaEntertainment}
            element={<EntertaineMePage />}
          />
          <Route path={dailyDoulaTools} element={<DailyDoulaToolsPage />} />
        </Route>
        <Route path={"tags"} element={<TagsList />} />
        <Route path={"sponsors"} element={<SponsorList />} />
        <Route path={toolsMenu}>
          <Route path={toolsMenuBanners} element={<ToolsBannerListPage />} />
          <Route path={toolsMenuSections} element={<ToolsMenuSectionsPage />} />
        </Route>
        <Route path={contentCollections}>
          <Route path={contentCollections}>
            <Route path={""} element={<ContentCollectionList />} />
            <Route path={"new"} element={<ContentCollectionPage />} />
            <Route path={":id"} element={<ContentCollectionPage />} />
          </Route>
          <Route path={contentMenu}>
            <Route path={""} element={<ContentMenuPage />} />
          </Route>
        </Route>
        <Route path={banners}>
          <Route path={""} element={<BannersListPage />} />
          <Route path={"new"} element={<BannerPage />} />
          <Route path={":id"} element={<BannerPage />} />
          <Route path={"changeorder"} element={<ChangeBannerList />} />
        </Route>
        <Route path={applinks}>
          <Route path={""} element={<AppLinksList />} />
          <Route path={"new"} element={<AppLinkPage />} />
          <Route path={":id"} element={<AppLinkPage />} />
        </Route>
        <Route path={appTakeOvers}>
          <Route path={""} element={<AppTakeOverListPage />} />
          <Route path={"new"} element={<AppTakeOverPage />} />
          <Route path={":id"} element={<AppTakeOverPage />} />
        </Route>
        <Route path={miniJourneyRoute}>
          <Route path={""} element={<MiniJourneyListPage />} />
          <Route path={"new"} element={<MiniJourneyPage />} />
          <Route path={":id"} element={<MiniJourneyPage />} />
        </Route>
        <Route path={addCompetition}>
          <Route path={""} element={<CompetitionsListPage />} />
          <Route path={"new"} element={<CompetitionPage />} />
          <Route path={":id"} element={<CompetitionPage />} />
          <Route path={"changeorder"} element={<ChangeCompetitionList />} />
        </Route>
        <Route path={images}>
          <Route path={""} element={<UploadImageListPage />} />
          <Route path={"new"} element={<UploadImagePage />} />
          <Route path={":id"} element={<UploadImagePage />} />
          <Route path={"changeorder"} element={<ChangeUploadImageList />} />
        </Route>
        <Route path={polls}>
          <Route path={""} element={<PollsList />} />
          <Route path={"new"} element={<PollPage />} />
          <Route path={":id"} element={<PollPage />} />
        </Route>
        <Route path={loyalty} element={<LoyaltyProvider />}>
          <Route path={missions}>
            <Route path={""} element={<MissionsListPage />} />
            <Route path={changeorder} element={<ChangeMissionsList />} />
          </Route>
          <Route
            path={levels}
            element={<CountryGuard component={LoyaltyLevelsInfoPage} />}
          />
        </Route>
      </Route>
      <Route path="*" element={<Navigate to="/cms" />} />
    </Routes>
  );
};
