import firebase from "../firebase";
import { CollectionPaths } from "../helper/collections";
import { CountryCodeService } from "./CountryCodeService";
import map from "lodash/map";
import { uploadImage } from "./serviceUtils";

const checklistTaskImageStoragePath = "checklist-task-image";
const checklistSponsorLogoStoragePath = "checklist-sponsor-logo";

export class ChecklistService extends CountryCodeService {
  constructor(countryCode: string) {
    super(countryCode);
  }

  static toChecklist = (doc: any): Checklist => {
    const data = doc.data();

    const tasks = map(data.tasks, itm => ({
      ...itm,
      translations: itm.translations ?? {},
    }));

    return {
      id: doc.id,
      ...data,
      translations: data.translations ?? {},
      sortOrder: data?.sortOrder ?? 1,
      tasks,
      ragCorpus: data.ragCorpus === undefined ? true : data.ragCorpus,
    };
  };

  subscribe = (
    callback: (err: Error | null, checklists: Checklist[]) => void
  ) =>
    firebase
      .firestore()
      .collection(CollectionPaths.Checklist[this.countryCode])
      .onSnapshot(
        snapshot => {
          const checklists = snapshot.docs.map(ChecklistService.toChecklist);
          callback(null, checklists);
        },
        err => {
          console.log(err);
          callback(err, []);
        }
      );

  create = (data: Partial<Checklist>) => {
    // Don't add ragCorpus if it's already defined in the data
    const dataToSave = {
      ...data,
    };

    // Only set default if ragCorpus is undefined
    if (data.ragCorpus === undefined) {
      dataToSave.ragCorpus = true;
    }

    return firebase
      .firestore()
      .collection(CollectionPaths.Checklist[this.countryCode])
      .add(dataToSave);
  };

  update = (id: string, data: Partial<Checklist>) => {
    // Don't modify the data at all during update
    return firebase
      .firestore()
      .collection(CollectionPaths.Checklist[this.countryCode])
      .doc(id)
      .update(data);
  };

  delete = (id: string) =>
    firebase
      .firestore()
      .collection(CollectionPaths.Checklist[this.countryCode])
      .doc(id)
      .delete();

  uploadChecklistTaskImage = async (
    file: Blob | ArrayBuffer,
    fileName: string
  ) => {
    const url = await uploadImage(
      file,
      checklistTaskImageStoragePath,
      fileName
    );
    return url;
  };

  uploadChecklistSponsorImage = async (
    file: Blob | ArrayBuffer,
    fileName: string
  ) => {
    const url = await uploadImage(
      file,
      checklistSponsorLogoStoragePath,
      fileName
    );
    return url;
  };
}
