export const ArticleIdParam = ":articleRouteParamId";

export const cmsKey = "cms";
export const cms = "/cms";

export const articles = "articles";
export const articlesSub = "articles";
export const articleNew = "new";
export const articleCategories = "article-categories";
export const articleVerifiers = "article-verifiers";
export const sponsors = "sponsors";
export const contentCollections = "contentCollections";
export const contentSub = "content";
export const contentMenu = "contentMenu";
export const deepLink = "deepLink";
export const banners = "banners";
export const applinks = "applinks";

export const appTakeOvers = "appTakeOvers";
export const addCompetition = "competition";
export const images = "images";

export const dietary = "dietary";
export const dietarySub = "dietary";
export const dietaryCategoriesSub = "dietarycategories";
export const dietaryContentSub = "dietarycontent";

export const popular = "popular";
export const popularMonths = "months";
export const popularWeeks = "weeks";
export const popularChild = "popular-child";
export const popularPregnancy = "popular-pregnancy";

export const recommended = "recommended";
export const recommendedMonths = "months";
export const recommendedWeeks = "weeks";

export const monthlyInformation = "monthly-information";
export const podcasts = "podcasts";
export const content = "content";
export const checkList = "checklists";
export const checkListCustomOffers = "checklist-custom-offers";
export const weekly = "weekly";
export const swedish = "swedish";
export const english = "english";
export const faq = "faq";
export const faqSub = "faq";
export const faqCategories = "faq-categories";
export const offers = "offers";
export const blogPost = "blog-posts";
export const blogCategories = "blog-categories";
export const influencers = "influencers";
export const notification = "notification";
export const notificationsPath = "/notifications";
export const notificationPregnancy = "notification-pregnancy";
export const notificationChildren = "notification-children";
export const highlight = "highlight";
export const dailyDoula = "daily-doula";
export const dailyDoulaPregnancy = "pregnancy";
export const dailyDoulaChild = "child";
export const dailyDoulaEntertainment = "entertainment";
export const dailyDoulaTools = "tools";
export const dailyDoulaOperationCenter = "dailyDoulaOperationCenter";
export const tipsSub = "tips";
export const polls = "polls";
export const miniJourneyRoute = "mini-journey";
export const milestones = "milestones";
export const milestonesPregnancy = "pregnancy-milestones";
export const milestonesChild = "child-milestones";
export const toolsMenu = "tools-menu";
export const toolsMenuBanners = "banners";
export const toolsMenuSections = "menuSections";
export const loyalty = "loyalty";
export const missions = "missions";
export const levels = "levels";
export const symptoms = "symptoms";
