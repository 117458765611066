import { useEffect, useState } from "react";
import { SymptomModal } from "./SymptomModal";
import BJList from "../../components/theme/components/BJList";
import { useCountry, useSymptoms } from "../../context";
import { BJColumnsType } from "../../components/theme/molecules/BJTable";
import { Sorter } from "../../components/theme/util/sorter";

export const SymptomsListPage = () => {
  const { currentCountry, primaryLocale } = useCountry();
  const { symptoms } = useSymptoms();
  const [showSymptomModal, setShowSymptomModal] = useState(false);
  const [originalTable, setOriginalTable] = useState([]);
  const [selectedSymptom, setSelectedSymptom] = useState<Symptom | null>(null);

  useEffect(() => {
    const symptomsTableSource = symptoms?.map(symptom => {
      return {
        key: symptom?.key,
        name: symptom?.translations[primaryLocale?.key]?.title ?? "",
        translations: symptom?.translations,
      };
    });
    setOriginalTable(symptomsTableSource);
  }, [primaryLocale, symptoms]);

  const onClickRow = (record: Symptom) => {
    return {
      onClick: () => {
        setSelectedSymptom(record);
      },
    };
  };

  const columns: BJColumnsType<Symptom> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 2,
      ellipsis: true,
      sorter: {
        compare: Sorter.DEFAULT,
      },
    },
  ];

  return (
    <>
      <BJList
        key={`symptomsList-${currentCountry.abb}`}
        addButtonCaption={"New Symptom"}
        title={"Symptoms"}
        OriginalList={originalTable}
        columns={columns}
        recordCountSuffix={"Symptoms"}
        onClickRow={onClickRow}
        onclick={() => {
          setShowSymptomModal(true);
        }}
      />
      <SymptomModal
        symptom={selectedSymptom}
        show={showSymptomModal || selectedSymptom !== null}
        onHide={() => {
          setSelectedSymptom(null);
          setShowSymptomModal(false);
        }}
      />
    </>
  );
};
