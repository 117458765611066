import { httpsCallable } from "firebase/functions";
import firebase, { functions } from "../firebase";
import { cloudFunctionNames } from "./../utils/consts";
import { CountryCodeService } from "./CountryCodeService";
import { CollectionPaths } from "../helper/collections";
import { logException } from "../utils/exceptionLogger";

export class FAQService extends CountryCodeService {
  constructor(countryCode: string) {
    super(countryCode);
  }

  static toQuestion = (doc: firebase.firestore.DocumentData): Question => {
    const data = doc.data();
    return {
      id: doc.id,
      ...data,
      translations: data.translations ?? {},
      ragCorpus: data.ragCorpus ?? true,
    };
  };

  subscribe = (callback: (err: Error | null, snapshot: Question[]) => void) =>
    firebase
      .firestore()
      .collection(CollectionPaths.FAQ[this.countryCode])
      .onSnapshot(
        snapshot => {
          const docs = snapshot.empty
            ? []
            : snapshot.docs.map(FAQService.toQuestion);
          callback(null, docs);
        },
        err => {
          logException(err);
          callback(err, []);
        }
      );

  create = (data: Omit<Question, "id">) =>
    firebase
      .firestore()
      .collection(CollectionPaths.FAQ[this.countryCode])
      .add(data);

  update = (id: string, data: Omit<Question, "id">) =>
    firebase
      .firestore()
      .collection(CollectionPaths.FAQ[this.countryCode])
      .doc(id)
      .update(data);

  deleteQuestion = (id: string) =>
    firebase
      .firestore()
      .collection(CollectionPaths.FAQ[this.countryCode])
      .doc(id)
      .delete();

  updateFaqSortOrder = async (docId: string, sortOrder: number) => {
    try {
      const updateFaqSortOrder = httpsCallable(
        functions,
        cloudFunctionNames.faqs.updateFaqSortOrder
      );
      const result = await updateFaqSortOrder({
        id: docId,
        sortOrder,
        countryCode: this.countryCode,
      });
      return result.data;
    } catch (error) {
      logException(error);
      throw error;
    }
  };
}
