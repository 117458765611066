import firebase from "../firebase";
import { CollectionPaths } from "../helper/collections";
import { logException } from "../utils/exceptionLogger";
import { CountryCodeService } from "./CountryCodeService";
import { uploadImage } from "./serviceUtils";

export class ArticleService extends CountryCodeService {
  constructor(countryCode: string) {
    super(countryCode);
  }

  static articleFileStoragePath = "articles";

  static toArticle = (doc: firebase.firestore.DocumentData): ArticleV2 => {
    const data = doc.data();

    return {
      id: doc.id,
      ...data,
      imageUrl: data.imageUrl || null,
      stretchedImageUrl: data.stretchedImageUrl || null,
      squareImageUrl: data.squareImageUrl || null,
      imageBlurhash: data?.imageBlurhash,
      stretchedBlurhash: data?.stretchedBlurhash,
      squareBlurhash: data?.squareBlurhash,
      videoUrl: data.videoUrl || null,
      audioUrl: data.audioUrl || null,
      offer1: null,
      offer2: null,
      verifierId: data.verifierId || null,
      sponsorId: data.sponsorId || null,
      sortOrder: data.sortOrder,
      sponsor: data.sponsor || null,
      verifier: data.verifier || null,
      tagWords: data.tagWords ?? [],
      translations: data.translations ?? {},
      ragCorpus: data.ragCorpus ?? true,
    };
  };

  static toArticleCategory = (
    doc: firebase.firestore.DocumentData
  ): ArticleCategory => {
    const data = doc.data();

    return {
      id: doc.id,
      sortOrder: data.sortOrder,
      icon: data.icon || "",
      template: data.template || "",
      show: data.show,
      translations: data.translations,
      restricted: data.restricted,
      level: data.level,
    };
  };

  subscribe = (
    callback: (err: Error | null, articles: ArticleV2[]) => void
  ) => {
    return firebase
      .firestore()
      .collection(CollectionPaths.Article[this.countryCode])
      .onSnapshot(
        snapshot => {
          const articles = snapshot.docs.map(ArticleService.toArticle);
          callback(null, articles);
        },
        err => {
          logException(err);
          callback(err, []);
        }
      );
  };

  create = (data: Omit<ArticleV2, "id">) =>
    firebase
      .firestore()
      .collection(CollectionPaths.Article[this.countryCode])
      .add(data);

  update = (id: Article["id"], data: Omit<ArticleV2, "id">) =>
    firebase
      .firestore()
      .collection(CollectionPaths.Article[this.countryCode])
      .doc(id)
      .update({ ...data });

  deleteArticle = (id: string) =>
    firebase
      .firestore()
      .collection(CollectionPaths.Article[this.countryCode])
      .doc(id)
      .delete();

  subscribeToCategories = (
    callback: (err: Error | null, snapshot: ArticleCategory[]) => void
  ) =>
    firebase
      .firestore()
      .collection(CollectionPaths.ArticleCategories[this.countryCode])
      .onSnapshot(
        snapshot => {
          const docs = snapshot.empty
            ? []
            : snapshot.docs.map(ArticleService.toArticleCategory);
          callback(null, docs);
        },
        err => {
          logException(err);
          callback(err, []);
        }
      );

  updateSortOrders = (articles: Article[]) => {
    const batch = firebase.firestore().batch();
    articles.forEach(article => {
      const ref = firebase
        .firestore()
        .collection(CollectionPaths.Article[this.countryCode])
        .doc(article.id);
      batch.update(ref, { sortOrder: article.sortOrder });
    });
    return batch.commit();
  };

  static uploadArticleImage = async (
    file: Blob | ArrayBuffer,
    fileName: string
  ) => {
    const url = uploadImage(
      file,
      ArticleService.articleFileStoragePath,
      fileName
    );
    return url;
  };
}
