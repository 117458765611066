import firebase from "../firebase";
import { SymptomType } from "../pages/Symptoms/SymptomModal";
import { logException } from "../utils/exceptionLogger";
import { deleteField } from "firebase/firestore";

const subscribe = (
  callback: (err: Error | null, countries: Country[]) => void
) =>
  firebase
    .firestore()
    .collection("countries")
    .doc("countriesContent")
    .onSnapshot(
      snapshot => {
        const countries = snapshot.data().content as Country[];
        callback(null, countries);
      },
      err => {
        logException(err);
        callback(err, []);
      }
    );

const subscribeSymptoms = (
  callback: (err: Error | null, symptoms: Symptom[]) => void,
  locale: string
) =>
  firebase
    .firestore()
    .collection("search")
    .doc(`symptoms_${locale}`)
    .onSnapshot(
      snapshot => {
        const symptoms = snapshot.data() ? snapshot.data().content ?? [] : [];
        callback(null, symptoms);
      },
      err => {
        logException(err);
        callback(err, []);
      }
    );

const update = (country: Country) =>
  firebase
    .firestore()
    .collection("countries")
    .doc("countriesContent")
    .update({
      [`content.${country.abb}`]: country,
    });

const getCountries = () => {
  return firebase
    .firestore()
    .collection("search")
    .doc("countries")
    .get()
    .then(doc => doc.data().content as CountryData);
};

const getLanguages = () => {
  return firebase
    .firestore()
    .collection("search")
    .doc("languages")
    .get()
    .then(doc => doc.data().content as LanguageData);
};

const getMeasurementSystems = () => {
  return firebase
    .firestore()
    .collection("search")
    .doc("measurementSystems")
    .get()
    .then(doc => doc.data() as MeasurementSystemMap);
};

const updateSymptom = async ({
  locale,
  key,
  translations,
}: {
  locale: string;
  key: string;
  translations: SymptomType;
}) => {
  try {
    const ref = firebase
      .firestore()
      .collection("search")
      .doc(`symptoms_${locale}`);

    const doc = await ref.get();

    if (doc.exists) {
      //update existing symptom under content array
      const content = doc.data()?.content ?? [];
      const index = content.findIndex((item: Symptom) => item.key === key);
      if (index !== -1) {
        content[index].translations = translations;
        await ref.set({ content }, { merge: true });
      }
      //add new symptom to content array
      else {
        content.push({ key, translations });
        await ref.set({ content }, { merge: true });
      }
    } else {
      //create new symptom under content array
      await ref.set({ content: [{ key, translations }] });
    }
    return true;
  } catch (err) {
    logException(err);
    throw err;
  }
};

const deleteSymptom = async ({
  locale,
  key,
}: {
  locale: string;
  key: string;
}) => {
  try {
    const ref = firebase
      .firestore()
      .collection("search")
      .doc(`symptoms_${locale}`);

    const doc = await ref.get();

    if (doc.exists) {
      let content = doc.data()?.content ?? [];

      content = content.filter((item: Symptom) => item.key !== key);
      await ref.set({ content }, { merge: true });
    }
    return true;
  } catch (err) {
    logException(err);
    throw err;
  }
};

export const CountryService = {
  subscribe,
  subscribeSymptoms,
  update,
  updateSymptom,
  deleteSymptom,
  getCountries,
  getLanguages,
  getMeasurementSystems,
};
