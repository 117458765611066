import firebase from "../firebase";
import { CollectionPaths } from "../helper/collections";
import { logException } from "../utils/exceptionLogger";
import { CountryCodeService } from "./CountryCodeService";

export type MonthInfoMetaData = {
  fieldType: string;
  monthOrWeek: number;
};
export class MonthlyInformationService extends CountryCodeService {
  constructor(countryCode: string) {
    super(countryCode);
  }
  toMonthlyInformation = (
    doc: firebase.firestore.DocumentData
  ): MonthInformation => {
    const data = doc.data();
    return {
      id: doc.id,
      ...data,
    };
  };

  subscribe = (
    callback: (
      err: Error | null,
      monthlyInformation: MonthInformation[]
    ) => void
  ) =>
    firebase
      .firestore()
      .collection(CollectionPaths.MonthlyInformation[this.countryCode])
      .onSnapshot(
        snapshot => {
          const monthlyInformation = snapshot.docs.map(
            this.toMonthlyInformation
          );
          callback(null, monthlyInformation);
        },
        err => {
          logException(err);
          callback(err, []);
        }
      );

  create = (data: MonthInformation) =>
    firebase
      .firestore()
      .collection(CollectionPaths.MonthlyInformation[this.countryCode])
      .add(data);

  update = (data: MonthInformation) =>
    firebase
      .firestore()
      .collection(CollectionPaths.MonthlyInformation[this.countryCode])
      .doc(data.id)
      .update({ ...data });

  updateMetadata = async (data: MonthInfoMetaData) => {
    const docRef = firebase
      .firestore()
      .collection("monthlyInformation")
      .doc(this.countryCode);

    const docSnapshot = await docRef.get();

    // Fetch current data and ensure it's an array
    const currentData: number[] =
      docSnapshot.data()?.metadata?.[data.fieldType] || [];

    // Add/Update the month or week to the array
    const updatedMetadata: number[] = Array.from(
      new Set([...currentData, data.monthOrWeek])
    ).sort((a, b) => a - b);

    await docRef.update({
      [`metadata.${data.fieldType}`]: updatedMetadata,
    });
  };

  deleteMetadata = async (data: MonthInfoMetaData) => {
    const docRef = firebase
      .firestore()
      .collection("monthlyInformation")
      .doc(this.countryCode);

    const docSnapshot = await docRef.get();

    // Fetch current data and ensure it's an array
    const currentData: number[] =
      docSnapshot.data()?.metadata?.[data.fieldType] || [];
    // Remove the month or week from the array
    const filteredData = currentData.filter(num => num !== data.monthOrWeek);

    await docRef.update({
      [`metadata.${data.fieldType}`]: filteredData,
    });
  };

  delete = (id: string) =>
    firebase
      .firestore()
      .collection(CollectionPaths.MonthlyInformation[this.countryCode])
      .doc(id)
      .delete();
}
