import React, { useState, useEffect, useMemo } from "react";
import { Dispatch, SetStateAction } from "react";
import { CommonContentType, ContentListType } from "../../utils";
import { AddArticlesModal } from "./modal/AddArticlesModal";
import { AddBlogPostModal } from "./modal/AddBlogPostModal";
import { AddFaqsModal } from "./modal/AddFaqModal";
import { AddOfferModal } from "./modal/AddOffersModal";
import { AddPodcastEpisodeModal } from "./modal/AddPodcastEpisodeModal";
import { ContentManager } from "./ContentList";
import { AddDietaryAdvicesModal } from "./modal/AddDietaryAdvicesModal";
import { AddContentCollectionsModal } from "./modal/AddContentCollectionsModal";
import { AddToolsModal } from "./modal/AddToolsModal";

interface Props {
  setManagedContent: Dispatch<SetStateAction<PopularContent[]>>;
  initialContent: PopularContent[] | { [id: string]: PopularContent } | null;
  maxContentLimit?: number;
  displayButtons: boolean;
  headerTitle?: string;
  contentListType?: ContentListType;
  excludedContentCollectionId?: string;
  enableOrder?: boolean;
  customModal?: {
    component: React.ComponentType<{
      show: boolean;
      onHide: () => void;
      selectedContent: CommonContentV2[];
      onAdd: (content: CommonContentV2[]) => void;
      maxNoOfContentLimit: number;
    }>;
    title: string;
  };
}

const contentOptions = [
  {
    item: CommonContentType.ARTICLE,
    label: "Article",
    value: CommonContentType.ARTICLE,
  },
  {
    item: CommonContentType.BLOG,
    label: "Blog post",
    value: CommonContentType.BLOG,
  },
  {
    item: CommonContentType.PODCAST,
    label: "Podcast episode",
    value: CommonContentType.PODCAST,
  },
  {
    item: CommonContentType.FAQ,
    label: "FAQ",
    value: CommonContentType.FAQ,
  },
  {
    item: CommonContentType.OFFER,
    label: "Offer",
    value: CommonContentType.OFFER,
  },
  {
    item: CommonContentType.DIETARYADVICE,
    label: "Dietary advices",
    value: CommonContentType.DIETARYADVICE,
  },
  {
    item: CommonContentType.CONTENTCOLLECTION,
    label: "Content collections",
    value: CommonContentType.CONTENTCOLLECTION,
  },
  {
    item: CommonContentType.TOOL,
    label: "Tools",
    value: CommonContentType.TOOL,
  },
];

export const ContentSelector: React.FC<Props> = ({
  setManagedContent,
  initialContent,
  maxContentLimit = 100,
  displayButtons,
  headerTitle,
  contentListType,
  excludedContentCollectionId,
  enableOrder,
  customModal: CustomModal,
}: Props) => {
  const [selectedContentType, setSelectedContentType] = useState<
    CommonContentType | "custom" | null
  >(null);
  const [content, setContent] = useState<CommonContentV2[]>([]);

  useEffect(() => {
    if (!initialContent) return;
    if (initialContent instanceof Array && initialContent.length > 0) {
      setContent(initialContent);
    } else if (
      typeof initialContent === "object" &&
      Object.values(initialContent).length > 0
    ) {
      setContent(Object.values(initialContent ?? []));
    }
  }, [initialContent]);

  useEffect(() => {
    if (content?.length >= 0) {
      setManagedContent(content);
    }
  }, [content, setManagedContent]);

  const addContent = (content: CommonContentV2[]) => {
    setSelectedContentType(null);
    setContent(content);
  };

  const filteredContentOptions = useMemo(() => {
    switch (contentListType) {
      case ContentListType.RecommendedContent:
        return contentOptions.filter(
          co =>
            ![
              CommonContentType.DIETARYADVICE,
              CommonContentType.CONTENTCOLLECTION,
            ].some(c => c == co.value)
        );
      case ContentListType.AllContent:
        return contentOptions;
      case ContentListType.PopularContent:
        return contentOptions.filter(
          co =>
            ![
              CommonContentType.CONTENTCOLLECTION,
              CommonContentType.DIETARYADVICE,
              CommonContentType.OFFER,
              CommonContentType.TOOL,
            ].some(c => c == co.value)
        );
      case ContentListType.OnlyContentCollection:
        return contentOptions.filter(
          co => co.value === CommonContentType.CONTENTCOLLECTION
        );
      case ContentListType.EntertainMeContent:
        return contentOptions.filter(
          co => co.value === CommonContentType.ARTICLE
        );
    }
  }, [contentListType]);

  const customContentOption = useMemo(
    () => ({
      item: "custom" as const,
      label: CustomModal?.title,
      value: "custom" as const,
    }),
    [CustomModal?.title]
  );

  const contentOptionsWithCustom = useMemo(
    () => [...filteredContentOptions, customContentOption],
    [filteredContentOptions, customContentOption]
  );

  return (
    <>
      <ContentManager
        contentOptions={contentOptionsWithCustom}
        title={headerTitle}
        displayButtons={displayButtons}
        maxContentLimit={maxContentLimit}
        initialContent={content}
        contentType={selectedContentType}
        onAdd={(type: CommonContentType) => {
          setSelectedContentType(type);
        }}
        onPressRemove={c => {
          setContent(old => old.filter(({ id }) => id !== c.id));
        }}
        onSave={c => setContent(c)}
        enableOrder={enableOrder}
      />
      <AddBlogPostModal
        show={selectedContentType === CommonContentType.BLOG}
        onHide={() => setSelectedContentType(null)}
        maxNoOfContentLimit={maxContentLimit}
        selectedContent={content}
        onAdd={addContent}
      />
      <AddArticlesModal
        show={selectedContentType === CommonContentType.ARTICLE}
        onHide={() => setSelectedContentType(null)}
        maxNoOfContentLimit={maxContentLimit}
        selectedContent={content}
        onAdd={addContent}
      />
      <AddDietaryAdvicesModal
        show={selectedContentType === CommonContentType.DIETARYADVICE}
        onHide={() => setSelectedContentType(null)}
        maxNoOfContentLimit={maxContentLimit}
        selectedContent={content}
        onAdd={addContent}
      />
      <AddContentCollectionsModal
        excludedContentSelectionId={excludedContentCollectionId}
        show={selectedContentType === CommonContentType.CONTENTCOLLECTION}
        onHide={() => setSelectedContentType(null)}
        maxNoOfContentLimit={maxContentLimit}
        selectedContent={content}
        onAdd={addContent}
      />
      <AddFaqsModal
        show={selectedContentType === CommonContentType.FAQ}
        onHide={() => {
          setSelectedContentType(null);
        }}
        maxNoOfContentLimit={maxContentLimit}
        selectedContent={content}
        onAdd={addContent}
      />
      <AddPodcastEpisodeModal
        show={selectedContentType === CommonContentType.PODCAST}
        selectedContent={content}
        onHide={() => setSelectedContentType(null)}
        onAdd={addContent}
      />
      <AddOfferModal
        show={selectedContentType === CommonContentType.OFFER}
        onHide={() => setSelectedContentType(null)}
        maxNoOfContentLimit={maxContentLimit}
        selectedContent={content}
        onAdd={addContent}
      />
      <AddToolsModal
        show={selectedContentType === CommonContentType.TOOL}
        onHide={() => setSelectedContentType(null)}
        selectedContent={content}
        onAdd={addContent}
        maxNoOfContentLimit={maxContentLimit}
      />
      {CustomModal && CustomModal.component && (
        <CustomModal.component
          show={selectedContentType === "custom"}
          onHide={() => setSelectedContentType(null)}
          selectedContent={content}
          onAdd={addContent}
          maxNoOfContentLimit={maxContentLimit}
        />
      )}
    </>
  );
};
