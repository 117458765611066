import moment from "moment";
import { convertToYears, Languages } from ".";
import { NUM_OF_MONTHS, HALF_YEARLY_MONTHS } from "./consts";
import { NUM_OF_WEEKS } from "./consts";

export type PickerDisplayType = {
  name: string;
  number: number;
};

export enum PickerType {
  months,
  weeks,
}

/**
 * Function to get months
 * @param {Languages} lang - Language to display months in
 * @param {boolean} showHalfYearly - Show half yearly months
 * @returns {months: PickerDisplayType[], findNameByMonthNumber: (monthNumber: number) => string}
 */
export const getMonths = (
  {
    lang = Languages.norwegian,
    showHalfYearly = false,
  }: { lang?: Languages; showHalfYearly?: boolean } = {
    lang: Languages.norwegian,
    showHalfYearly: false,
  }
) => {
  const getLangName = () => {
    switch (lang) {
      case Languages.swedish:
        return "Månad";
      case Languages.norwegian:
        return "Måned";
      case Languages.english:
        return "Month";
      default:
        return "";
    }
  };

  const langName = getLangName();

  const months: PickerDisplayType[] = Array.from(
    { length: NUM_OF_MONTHS },
    (_, i) => ({
      name: `${langName} ${i + 1}`,
      number: i + 1,
    })
  ).concat(
    showHalfYearly
      ? HALF_YEARLY_MONTHS.map(x => ({
          name: `${langName} ${x} ${convertToYears(x)}`,
          number: x,
        }))
      : []
  );

  const findNameByMonthNumber = (monthNumber: number) => {
    const name = months.find(x => x.number === +monthNumber)?.name;
    return name;
  };

  return { months, findNameByMonthNumber };
};

export const getWeeks = (lang: Languages = Languages.swedish) => {
  const weeks: PickerDisplayType[] = [];
  const langName = () => {
    switch (lang) {
      case Languages.swedish:
        return "Vecka";
      case Languages.norwegian:
        return "Uke";
      case Languages.english:
        return "Week";
      default:
        return "";
    }
  };

  for (let w = 1; w <= NUM_OF_WEEKS; w++) {
    weeks.push({
      name: `${langName()} ${w}`,
      number: w,
    });
  }
  return { weeks };
};

export const resetSeconds = (time: moment.Moment | Date) => {
  return moment(time).set("second", 0);
};

export const isCurrentTimeWithinRange = (startTime: Date, endTime: Date) => {
  const currentTime = resetSeconds(moment());
  return (
    (!startTime && !endTime) ||
    (startTime &&
      endTime &&
      resetSeconds(startTime).isSameOrBefore(currentTime) &&
      resetSeconds(endTime).isSameOrAfter(currentTime))
  );
};
