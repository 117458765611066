import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { Row, Col, Modal } from "antd";
import { useCountry, usePoll } from "../../context";
import { v4 as uuidv4 } from "uuid"; //
import {
  AudienceType,
  DisplayUnitType,
  formValidationError,
  ScreenInMobile,
  range as isInRange,
  getMaxSortOrder,
  formatDateStringToTimeStamp,
  formatTimeStampToDate,
} from "../../utils";
import { AiTranslator, FormEdit, FormEditType } from "../../components";
import { commonErrors } from "../../language";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  BJInputFormItem,
  BJNotification,
  BJSwitchFormItem,
  NotificationType,
} from "../../components/theme";
import { cms, polls } from "../../routes/routeConsts";
import { BJMdFormItem } from "../../components/theme/molecules/formItems/BJFormMarkdown";
import { AnswersList } from "./AnswerList";
import { AnswerModal } from "./AnswerModal";
import moment from "moment";
import _ from "lodash";
import {
  BJAudienceMultipleSchema,
  convertExtraToSegments,
  convertSegmentsToExtra,
  BJAudienceMultipleValues,
  BJAudienceMultiple,
} from "../../components/theme/components/BJAudienceMultiple";
import { BJFormDateRangePicker } from "../../components/theme/molecules/formItems/BJFormDateRangePicker";
import BJButton from "../../components/theme/atoms/Button";

interface FormValues extends BJAudienceMultipleValues {
  withPercentage: boolean;
  withCorrectAnswer: boolean;
  isActive: boolean;
  startEnd?: [any, any];
  translations: {
    [locale: string]: {
      title: string;
      body: string;
    };
  };
}
const { requiredError } = commonErrors;

export const PollPage = () => {
  const navigate = useNavigate();
  const { id } = useParams<string>();
  const { poll, loading: loadingPoll, pollService } = usePoll(id);
  const { currentCountry, primaryLocale } = useCountry();

  const schema = yup.object().shape({
    startEnd: yup.array().when(["audiences"], {
      is: (audiences: { audience: AudienceType }[]) => {
        return audiences?.some(
          a =>
            a.audience === AudienceType.All ||
            a.audience === AudienceType.Partner
        );
      },
      then: yup.array().of(yup.date()).required(requiredError),
      otherwise: yup.array().nullable(),
    }),
    audiences: yup.array().min(1, "audiences field must have at least 1 items"),
    ...BJAudienceMultipleSchema(true),
    translations: yup.object().shape(
      currentCountry?.locales.reduce((acc, item) => {
        acc[item.key] = yup.object().shape({
          title: yup.string().nullable(),
          body: yup.string().nullable(),
        });
        return acc;
      }, {} as any)
    ),
  });

  const methods = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      audiences: [],
    },
  });

  const {
    formState: { errors, dirtyFields },
    handleSubmit,
    reset,
    watch,
    control,
    setValue,
  } = methods;
  const [answerModalVisible, setAnswerModalVisible] = useState(false);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);

  const [answers, setAnswers] = useState<Answer[]>([]);
  const [selectAnswer, setSelectAnswer] = useState<Answer | null>(null);
  const { audiences, withPercentage, withCorrectAnswer, translations } =
    watch();

  const mappedAudience = audiences.map(a => a.audience);

  useEffect(() => {
    if (poll?.answers) {
      setAnswers(poll.answers);
    }
  }, [poll?.answers]);

  useEffect(() => {
    if (withCorrectAnswer) {
      setValue("withPercentage", false);
    } else if (withPercentage) {
      setValue("withCorrectAnswer", false);
    }
  }, [setValue, withCorrectAnswer, withPercentage]);

  useEffect(() => {
    if (loadingPoll || poll === null) {
      return;
    }
    if (poll) {
      reset({
        translations: poll.translations,
        withPercentage: poll.withPercentage,
        withCorrectAnswer: poll.withCorrectAnswer,
        isActive: poll.isActive,
        audiences: convertExtraToSegments(poll.extra),
        startEnd: [poll.startTime, poll.endTime],
        cities: poll.cities,
      });
    }
  }, [poll, reset, loadingPoll]);

  const onHandleSubmit = () => {
    handleSubmit(onSubmit, formValidationError)();
  };

  const onSubmit = async (data: FormValues) => {
    if (
      withCorrectAnswer &&
      answers.find(a => a.correctAnswer === true) === undefined
    ) {
      throw new Error("Please select at least one correct answer");
    }

    const _poll: Partial<Poll> = {
      translations: data.translations,
      withPercentage: data.withPercentage,
      withCorrectAnswer: data.withCorrectAnswer,
      isMultiChoice: false,
      answers: answers || [],
      screens: [
        { screen: ScreenInMobile.Child, position: 0 },
        { screen: ScreenInMobile.Pregnancy, position: 0 },
      ],
      isActive: data.isActive,
      startTime: data.startEnd
        ? formatDateStringToTimeStamp(data.startEnd[0], false)
        : null,
      endTime: data.startEnd
        ? formatDateStringToTimeStamp(data.startEnd[1], false)
        : null,
      scheduledAt: data.startEnd
        ? formatDateStringToTimeStamp(data.startEnd[0], false)
        : null,
      extra: convertSegmentsToExtra(data?.audiences),
      segment: {
        type: !_.isEmpty(data.audiences) ? data.audiences[0].audience : null,
        rangeUnit: DisplayUnitType.Day,
        range: !_.isEmpty(data.audiences) ? data.audiences[0].range : null,
      },
      cities: data.cities,
    };

    if (poll) {
      await pollService?.update(poll.id, _poll);
    } else {
      const { id } = await pollService?.create({
        ..._poll,
        createdAt: formatDateStringToTimeStamp(moment().toISOString(), false),
      });
      return navigate(`../${id}`);
    }
  };

  const handleDuplicatePoll = async () => {
    if (!poll) return;

    setIsConfirmModalVisible(false);

    try {
      const newAnswers = poll.answers.map(answer => ({
        ...answer,
        id: uuidv4(),
      }));

      const { id: newPollId } = await pollService?.create({
        ...poll,
        id: undefined,
        answers: newAnswers,
        startTime: formatTimeStampToDate(poll.startTime),
        endTime: formatTimeStampToDate(poll.endTime),
        scheduledAt: formatTimeStampToDate(poll.scheduledAt),
        createdAt: formatDateStringToTimeStamp(moment().toISOString(), false),
      });

      BJNotification({
        type: NotificationType.Success,
        message: "Record duplicated",
        description: `${newPollId ?? "Record"} created successfully`,
      });

      navigate(`../${newPollId}`);
    } catch (error) {
      console.error("Error duplicating poll:", error);
    }
  };

  const renderConfirmModal = () => {
    return (
      <Modal
        title="Confirm Duplication"
        visible={isConfirmModalVisible}
        onOk={handleDuplicatePoll}
        onCancel={() => setIsConfirmModalVisible(false)}
        okText="Confirm"
        cancelText="Cancel"
      >
        <p>Are you sure you want to duplicate this poll?</p>
      </Modal>
    );
  };

  const onRemove = async () => {
    if (poll) {
      await pollService?.deletePoll(poll.id);
      return navigate(`${cms}/${polls}`);
    }
  };

  const isDirty =
    !!Object.keys(dirtyFields).length || !_.isEqual(answers, poll?.answers);

  const renderAnswerModal = () => {
    return (
      <AnswerModal
        show={answerModalVisible || !!selectAnswer}
        onHide={() => {
          setAnswerModalVisible(false);
          setSelectAnswer(null);
        }}
        answer={selectAnswer}
        withPercentage={withPercentage}
        withCorrectAnswer={withCorrectAnswer}
        onAdd={_answer => {
          if (_answer) {
            const maxSort = getMaxSortOrder(answers);
            const newAnswer = { ..._answer, sortOrder: maxSort + 1 };
            setAnswers([...answers, newAnswer]);
          }
        }}
        onDelete={id => {
          setAnswers(answers.filter(a => a.id !== id));
        }}
        onUpdate={_answer => {
          setAnswers(prev =>
            prev.map(a => {
              return a.id === _answer.id ? _answer : a;
            })
          );
        }}
      />
    );
  };

  const onSetTranslatedValue = (value: { data: string[] }) => {
    const data = value.data.map(item => item);

    let title;
    let body;

    const clonedTitle = data[0]
      .split(",")[0]
      ?.replace("[", "")
      ?.replace(/['"]+/g, "")
      .trim();
    const clonedBody = data[0]
      .split(",")[1]
      ?.replace("]", "")
      ?.replace(/['"]+/g, "")
      .trim();

    [
      translations?.[primaryLocale?.key]?.title,
      translations?.[primaryLocale?.key]?.body,
    ].forEach((item, index) => {
      if (data[0].includes("None")) {
        if (index === 0 && !clonedTitle.includes("None")) {
          title = clonedTitle;
        } else if (index === 1 && !clonedBody.includes("None")) {
          body = clonedBody;
        }
      } else {
        if (index === 0 && item !== undefined) {
          title = clonedTitle;
        } else if (index === 1 && item !== undefined) {
          if (clonedBody === undefined) {
            body = data[0];
            return;
          }
          body = clonedBody;
        }
      }
    });

    setValue("translations.en.title", title);
    setValue("translations.en.body", body);
  };

  const startEndRequired =
    audiences &&
    audiences.length > 0 &&
    (mappedAudience.includes(AudienceType.All) ||
      mappedAudience.includes(AudienceType.Partner));

  return (
    <FormProvider {...methods}>
      <FormEdit
        backRoutePath={`${cms}/${polls}`}
        onRemove={onRemove}
        hasValidationErrors={Object.keys(errors).length !== 0}
        enableSave={isDirty}
        title={
          poll ? poll.translations?.[primaryLocale?.key]?.title : "New Poll"
        }
        id={poll?.id}
        editType={poll?.id ? FormEditType.EDIT : FormEditType.ADD}
        loading={loadingPoll}
        onSubmit={onHandleSubmit}
        recordIdentifier={poll?.id}
        localeSupported
        errors={errors as any}
      >
        {locale => (
          <>
            {renderAnswerModal()}
            {renderConfirmModal()}
            <Row gutter={{ md: 20 }}>
              <Col md={24} lg={12}>
                <BJButton onClick={() => setIsConfirmModalVisible(true)}>
                  Duplicate Poll
                </BJButton>
                <BJInputFormItem
                  control={control}
                  label={`Title (${locale?.label})`}
                  key={`translations.${locale?.key}.title`}
                  fieldName={`translations.${locale?.key}.title`}
                  error={!!errors?.translations?.[locale?.key]?.title}
                  message={errors?.translations?.[locale?.key]?.title?.message}
                  autoFocus
                />
                <BJMdFormItem
                  control={control}
                  error={!!errors?.translations?.[locale?.key]?.body}
                  label={`Body (${locale?.label})`}
                  message={errors?.translations?.[locale?.key]?.body?.message}
                  fieldName={`translations.${locale?.key}.body`}
                  key={`translations.${locale?.key}.body`}
                />
                <BJSwitchFormItem
                  horizontal
                  control={control}
                  label={"Display Percentage"}
                  fieldName={"withPercentage"}
                />
                <BJSwitchFormItem
                  horizontal
                  control={control}
                  label={"Correct/incorrect answers"}
                  fieldName={"withCorrectAnswer"}
                />
                <AnswersList
                  initialAnswers={answers}
                  onAdd={() => setAnswerModalVisible(true)}
                  onSelect={(answer: Answer) => {
                    setSelectAnswer(answer);
                  }}
                  onUpdate={(_answers: Answer[]) => {
                    setAnswers(_answers);
                  }}
                  withPercentage={withPercentage}
                />
              </Col>
              <Col md={24} lg={12}>
                <AiTranslator
                  locale={locale}
                  data={[
                    translations?.sv?.title?.length > 0
                      ? translations?.sv?.title?.trim()
                      : undefined,
                    translations?.sv?.body?.length > 0
                      ? translations?.sv?.body?.trim()
                      : undefined,
                  ]}
                  onSetValue={onSetTranslatedValue}
                />
                <BJSwitchFormItem
                  horizontal
                  control={control}
                  label={"Active"}
                  fieldName={"isActive"}
                  required
                />
                <BJAudienceMultiple
                  isPoll={true}
                  isAllowedDate={true}
                  isNewStructure={true}
                  showCities
                />
                <BJFormDateRangePicker
                  label="Start | End"
                  fieldName={"startEnd"}
                  control={control}
                  required={startEndRequired}
                  error={!!errors?.startEnd}
                  message={
                    (errors?.startEnd as unknown as { message: string })
                      ?.message ||
                    errors?.startEnd?.map((e: any) => e.message).join(", ")
                  }
                  format={"YYYY-MM-DD HH:mm"}
                  disabledDate={d =>
                    moment(moment(d).format("YYYY-MM-DD HH:mm")).isSameOrBefore(
                      moment(moment().format("YYYY-MM-DD HH:mm"))
                        .add(-1, "days")
                        .add(11, "hour")
                        .add(59, "minutes")
                    ) ||
                    moment(moment(d).format("YYYY-MM-DD HH:mm")).isSameOrAfter(
                      moment(
                        moment().add(1, "month").format("YYYY-MM-DD HH:mm")
                      )
                    )
                  }
                  size={"large"}
                  showTime
                  placeholder={["Start Date Time", "End Date Time"]}
                  //in future we can enable this if we want to not allow user to select date for poll in update mode
                  // disabled={poll !== null}
                  extra={"Poll will be displayed on selected date"}
                />
              </Col>
            </Row>
          </>
        )}
      </FormEdit>
    </FormProvider>
  );
};
