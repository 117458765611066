import { Link, useParams } from "react-router-dom";
import { useReports } from "../../../hooks/useReports";
import { CenteredSpinner } from "../../../components/CenteredSpinner";
import { BJFlex } from "../../../components/styled";
import { StyledIframe } from "../../../components/styled/BJCommonStyles";

export const ReportContent = () => {
  const { menuId, reportId } = useParams();
  const { reports, menus, loading } = useReports();

  if (loading) return <CenteredSpinner />;

  const selectedMenu = menus?.find(menu => menu.id === menuId);
  const selectedReport = reports?.find(report => report.id === reportId);

  if (selectedMenu && !selectedReport) {
    const reportsForMenu = reports.filter(
      report => report.parentKey === menuId
    );
    return (
      <div>
        <h2>{selectedMenu.title}</h2>
        <ul>
          {reportsForMenu.map(report => (
            <li key={report.id}>
              <Link to={`/analytics/${menuId}/${report.id}`}>
                {report.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  if (selectedReport) {
    return (
      <BJFlex row centerJustify>
        <StyledIframe src={selectedReport.url} allowFullScreen />
      </BJFlex>
    );
  }
  return <p>No report or menu found for this route.</p>;
};
